
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
body {
  overflow-x: hidden;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 200px !important;
}

.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 0px 10px;
  font-weight: 500;
  font-size: 15px;
}

.sign_btn {
  padding: 10px 25px;
  background-color: black;
  color: white;
  border: 1px solid black;
  transition: all 0.5s;
  text-decoration: none;
}
.sign_btn:hover {
  background-color: rgb(252, 252, 252);
  color: rgb(0, 0, 0);
  border: 1px solid black;
}
.as_custtomer_btn {
  padding: 20px 80px;
  background: #edac3e;
  border: 2.13057px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.css-172th5s-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #edac3e !important;
}
.css-172th5s-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #edac3e !important;
}
.set_up_btn {
  padding: 20px 85px;
  background: #ffffff;
  border: 2.13057px solid #949494;
  color: rgb(58, 58, 58);
  text-decoration: none;
  transition: all 0.5s;
  margin-top: 100px;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
  outline: none;
}
.PhoneInput {
  display: flex;
  align-items: center;
  margin-top: 18px;
  border-bottom: 1px solid rgb(202, 202, 202);
  /* color: black; */
  padding: 10px 0px;
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  z-index: 1;
  color: #474747;
  font-weight: normal;
  font-size: 14px;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

#root {
  width: 100%;
  height: 100%;
}
i.fa-solid.fa-plus.cercle {
  border: 2px solid #595959;
  border-radius: 12px;
  padding: 4px;
  font-size: 13px;
}
.billa-420:focus-visible {
  outline: none !important;
}
.billa-420 {
  margin-top: -4px !important;
}
.z_squre {
  margin-left: 9rem !important;
}
.input_search {
  height: 44px;
  width: 257px;
  background-color: white;
  border: 1px solid rgb(87, 87, 87);
}
.input_search_one {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  background-color: transparent;
}

.footer_down {
  background-color: #242626;
  height: auto;
}
.footer_up {
  background-color: #fbf5ef;
  height: auto;
}
.free_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: rgb(44, 44, 44);
}
.asked_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
  .about_ft {
    text-align: left;
  }
  button.create_account_btn.mt-3 {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 49px !important;
}
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}
.footer_nd_list {
  float: right;
}
/* footer css end */

.button_a1 {
  /* color: var(--bs-nav-pills-link-active-color) !important; */
  background-color: #edac3e !important;
  border: 1px solid #edac3e;
  padding: 12px 56px;
  border-radius: 0px;
  color: white !important;
}
.button_a2 {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: transparent !important;
  border: 1px solid #ff0000 !important;
  padding: 12px 56px;
  border-radius: 0px;
  color: rgb(255, 0, 0) !important;
}

.custom__modal {
  min-width: 600px !important;
}

.book_now_btn1 {
  border: 0.954545px solid #edac3e;
  padding: 15px 40px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
  cursor: pointer;
}

.book_now_btn2 {
  border: 0.954545px solid #edac3e;
  padding: 15px 40px;
  background-color: #edac3e;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.5s;
  cursor: pointer;
}

.book_now_btn2:hover {
  background-color: #ffffff;
  color: #edac3e;
}

.book_now_btn1:hover {
  background-color: #edac3e;
  color: white;
}

.minus,
.plus {
  display: inline-block;
  width: 20px;
  height: 25px;
  background-color: #edac3e;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.num {
  padding: 0 10px;
}

.coppen_up_bg {
  background-color: #edac3e;
  border-radius: 10px;
  box-shadow: 3px 5px 15px #b3b3b3;
}

/* ======Login ===== */

.main_splash {
  /* min-width: 100%; */
  min-height: fit-content;
  /* background-color: #FBF5EF; */
  height: 100vh;
  /* position: relative; */
  padding: 0px;
}
.navbar-expand-lg {
  background-color: #fbf5ef;
}
.navbar_list li {
  margin-left: 1px;
  display: inline-block;
  list-style: none;
  padding: 0px 10px;
  font-weight: 500;
  font-size: 15px;
}
/* .login_btn{
    padding: 10px 25px;
    background-color: white;
    border: 1px solid black;
    color: black;
    transition: all 0.5s;
    text-decoration: none;
} */
/* .login_btn:hover{
    background-color: #EDAC3E;
    border: 1px solid #EDAC3E;
    color: white;
} */
.sign_btn {
  padding: 10px 25px;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.sign_btn:hover {
  background-color: rgb(252, 252, 252);
  color: rgb(0, 0, 0);
  border: 1px solid black;
}
@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: block;
    padding: 10px 10px;
  }
  .navbar_list {
    margin-left: -40px;
  }
}
@media screen and (min-width: 0px) and (max-width: 1820px) {
  .main_splash {
    height: auto !important;
  }
}

/* resposive navbar start */
@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: inline-block;
    padding: 10px 18px;
  }
}
/* resposive navbar end */

.first_section_discover {
  height: auto;
  background-color: transparent;
  /* position: absolute; */
}
.first_section_discover_left h1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 100;
  font-size: 59px;
  line-height: 85px;
  text-transform: capitalize;
}

.as_customer_right img {
  /* margin-top: 100px; */
}
.as_customer_right h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}
.as_custtomer_btn {
  padding: 20px 80px;
  background: #edac3e;
  border: 2.13057px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.as_custtomer_btn:hover {
  box-shadow: 2px 5px 7px black;
  color: black;
}
.set_up_btn {
  padding: 20px 85px;
  background: #ffffff;
  border: 2.13057px solid #949494;
  color: rgb(58, 58, 58);
  text-decoration: none;
  transition: all 0.5s;
  margin-top: 100px;
}
.set_up_btn:hover {
  box-shadow: 2px 5px 7px black;
  color: black;
}
.icons_list i {
  padding: 0px 7px;
  font-size: 14px;
}
.name_input_login {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(202, 202, 202) !important;
  line-height: 45px;
  font-family: "Rufina";
  outline: none;
  border-radius: 0px;
  font-size: inherit;
  height: 59px;
}

.name_input_signup {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(202, 202, 202) !important;
  line-height: 105px !important;
  font-family: "Rufina";
  outline: none;
  border-radius: 0px;
  font-size: inherit;
  height: 43px;
  color: #3a3a3a !important;
}
.list_your_p {
  align-items: center;
  color: #a0a0a0;
  font-family: Rufina;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.create_account_btn {
  padding: 15px 90px;
  background: #edac3e;
  border: 2.13057px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.create_account_btn:hover {
  color: #edac3e;
  border: 2.13057px solid #edac3e;
  background-color: white;
}
.create_account_google_btn {
  padding: 11px 24px;
  /* background: #EDAC3E; */
  border: 1.13057px solid #8f8f8f;
  color: rgb(131 131 131);
  text-decoration: none;
  transition: all 0.5s;
  font-weight: 400;
  font-size: 14px;
}
.create_account_facebook_btn {
  padding: 11px 9px;
  /* background: #EDAC3E; */
  border: 1.13057px solid #8f8f8f;
  color: rgb(131 131 131);
  text-decoration: none;
  transition: all 0.5s;
  font-weight: 400;
  font-size: 14px;
}
.already_account_p {
  font-size: 15px;
  /* font-family: 'Rufina'; */
  color: #8f8f8f;
}
.already_account_p a {
  color: #edac3e;
}
.hide_passsword span {
  float: right;
  color: #979595;
  font-size: 13px;
  font-family: "Rufina";
}
.hide_passsword span a {
  color: #979595;
  text-decoration: none;
}

.toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -25px;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: transparent;
  /* outline: 0; */
  box-shadow: none;
}
.form-control {
  outline: 1px solid #e3e3e3 !important;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
  color: #787878;
  margin-top: -33px !important;
}

.footer_ullll li {
  color: rgb(190, 190, 190) !important;
  display: inline-block;
  padding: 0px 50px;
}
/* footer css end */
.google_login {
  border: 1px solid #8f8f8f !important;
  box-shadow: none !important;
  padding: 0px 26px 0px 0px !important;
}

.modal-body {
  height: 100% !important;
  overflow-y: scroll;
}
.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: 0px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 2px 7px 12px 8px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  -webkit-filter: none;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}

.share_h5 {
  text-align: center;
  font-family: "Rufina";
}

.share_icon {
  text-align: center;
  font-size: 70px;
}

.share_div_start {
  /* text-align: center; */
}
.share_div_start_left {
  text-align: left !important;
}
.share_div_start_left input {
  /* outline: rgb(255, 108, 108) !important; */
}

.share_div_start h5 {
  font-weight: 500;
  font-family: "Rufina";
}

.share_bb {
  background-color: #edac3e;
  padding: 7px 30px;
  border: 1px solid #edac3e;
  color: white;
}

.share_b2 {
  background-color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #edac3e;
  color: #edac3e;
}

.share_btn1 {
  background-color: transparent;
  padding: 7px 11px;
  border: 1px solid #edac3e;
  color: #ffffff;
  margin-left: 7px !important;
  transition: all 0.5s;
  float: right;
}

.share_btn1:hover {
  background-color: #edac3e;
  /* padding: 7px 30px; */
  border: 1px solid #ffffff;
  color: white;
}

.coppen_up_bg h4 {
  font-family: "Rufina";
  font-weight: 600;
  font-size: 22px;
}

.coppen_up_bg p {
  /* font-family: 'Rufina'; */
  font-weight: 400;
  color: rgb(250, 250, 250);
}

.coppen_up_bg h2 {
  font-family: "Rufina";
  font-weight: 700;
}

.buttonzz {
  position: relative;
  padding: 8px 10px;
  border: 2px dotted black;
  font-size: 0.835em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  font-weight: bold;
  color: #000;
  background: #fff;
  transition: background 0.275s;
}

.buttonzz:hover,
.buttonzz:focus {
  background: #ea2237;
  color: #fff;
  cursor: pointer;
}

.buttonzz_1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  /* margin: 0px 10px 10px 7px !important; */
  /* font-family: 'Rufina'; */
  font-size: 17px;
  font-weight: 500;
  position: relative;
}
/* .buttonzz_1::after{
    content: 'Expiry date';
    position: absolute;
    width: 117px;
    top: -24px;
    font-weight: 600;
    left: 0px;
    color: #bfa1a1;
} */

.buttonzz1 {
  color: white;
  text-decoration: none;
  margin: 10px !important;
  margin-top: 20px;
  font-family: "Rufina";
  font-size: 19px;
  /* position: relative; */
}

.coppen_up_bg {
  background-color: #edac3e;
  border-radius: 10px;
  box-shadow: 3px 5px 15px #b3b3b3;
}

.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: 0px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 2px 7px 12px 8px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  -webkit-filter: none;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}
.coppen_up {
  border-radius: 10px;
}
.buttonz {
  margin-left: -53px !important;
}
/* .share_bb {
  background-color: #edac3e;
  padding: 7px 30px;
  border: 1px solid #edac3e;
  color: white;
} */
.as_custtomer_btn1 {
  background-color: #edac3e !important;
  display: inline-flex;
  align-items: center;
  color: white !important;
  padding: 8px 38px !important;
  border-radius: 0px;
  border: 2px solid rgb(150, 150, 150) !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  box-shadow: none !important;
}
.as_custtomer_btn1 div {
  background-color: #edac3e !important;
}
.as_custtomer_btn2 {
  background-color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  color: rgb(66, 66, 66) !important;
  padding: 8px 38px !important;
  border-radius: 0px;
  border: 2px solid rgb(150, 150, 150) !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  box-shadow: none !important;
}
.as_custtomer_btn2 div {
  background-color: #ffffff !important;
}
.as_custtomer_btn_facebook {
  background-color: #edac3e !important;
  display: inline-flex;
  align-items: center;
  color: white !important;
  padding: 16px 52px !important;
  border-radius: 0px;
  border: 2px solid rgb(150, 150, 150) !important;
  font-size: 16px !important;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  box-shadow: none !important;
}
.add_icon {
  margin-right: 10px;
  font-size: 17px !important;
}

/* ===========Login End===== */

/* ------------ Create Business Page Start ----------------- */


.main_splash {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: 599px !important;
  /* position: relative; */
  /* background-image: url(../src/app/assets/images/redius1.png); */
  background-size: 17% 54%;
  background-repeat: no-repeat;
  background-position: top right;
  margin-bottom: 275px;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 0px 10px;
  font-weight: 500;
  font-size: 15px;
}
.login_btn {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid black;
  color: black;
  transition: all 0.5s;
  text-decoration: none;
  font-weight: bold;
}
.login_btn:hover {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(255, 255, 255);
  color: white;
}
.help1 {
  font-weight: 600 !important;
}
.sign_btn {
  padding: 10px 25px;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.sign_btn:hover {
  background-color: rgb(252, 252, 252);
  color: #edac3e;
  border: 1px solid #edac3e;
}

.sign_btn.createBusiness {
  padding: 10px 25px;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.sign_btn.createBusiness:hover {
  background-color: rgb(252, 252, 252);
  color: #edac3e;
  border: 1px solid #edac3e;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: block;
    padding: 10px 10px;
  }
  .navbar_list {
    margin-left: -40px;
  }
  .first_section_discover_left h1 {
    font-family: "Rufina";
    font-style: normal;
    font-weight: 100;
    font-size: 52px;
    line-height: 85px;
    text-transform: capitalize;
  }
  .first_section_discover_right_img1 {
    top: 50px !important;
    right: 40px !important;
    width: 80%;
  }
  .first_section_discover_right_img2 {
    top: 90px !important;
    width: 80%;
    right: 0px !important;
  }
  .first_section_discover_right_img2:hover {
    right: 40px !important;
    top: 50px !important;
  }
  .second_sectionn {
    margin-top: 664px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .second_sectionn {
    margin-top: 554px !important;
  }
  .first_section_discover_left h1 {
    font-size: 52px;
  }
  .all_set_container {
    width: 100% !important;
  }
  .hbibi_img {
    position: static !important;
  }
  .delivers_ne_h5 {
    width: 100% !important;
  }
  .delivers_ne_h55 {
    width: 100% !important;
  }
  .delivers_ne_p {
    width: 100% !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 1400px) {
  .main_splash {
    height: auto;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .second_sectionn {
    margin-top: 144px !important;
  }
  
}
@media screen and (min-width: 320px) and (max-width: 497px) {
  .share_now_btnn {
    position: relative;
    left: 30px !important;
    top: -30px !important;
  }
  .style_now img {
    width: 100%;
  }
  .products_name_span_float_btn {
    padding: 16px 2px !important;
  }
  .shampoo_img_set {
    margin-bottom: 88px;
    width: 100px;
  }
}

/* resposive navbar start */
@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: inline-block;
    padding: 10px 18px;
  }
}
/* resposive navbar end */
.first_section_discover {
  height: auto;
  background-color: transparent;
  /* position: absolute; */
}
.first_section_discover_left h1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 100;
  font-size: 59px;
  line-height: 85px;
  text-transform: capitalize;
}

.first_section_discover_right {
  position: relative;
}
.first_section_discover_right_img1 {
  position: absolute;
  right: 0px;
}
.first_section_discover_right_img2 {
  position: absolute;
  right: -44px;
  top: 44px;
  transition: all 0.5s;
}
.first_section_discover_right_img2:hover {
  right: 0px;
  top: 0px;
}
.secrch_btn {
  padding: 15px 50px;
  color: white;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.secrch_btn:hover {
  color: #edac3e;
  background-color: white;
  border: 1px solid #edac3e;
}
.secrch_btn.createBusiness {
  padding: 15px 50px;
  color: white;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.secrch_btn.createBusiness:hover {
  color: #edac3e;
  background-color: white !important;
  border: 1px solid #edac3e !important;
}
.contact_us_list li {
  display: inline-block;
}
.contact_us_list {
  margin-left: -25px;
}
.contact_us_list li:nth-child(2) {
  padding-left: 25px;
}
.contact_us_list li:nth-child(3) {
  padding-left: 25px;
}
.second_sectionn {
  margin-top: 300px;
}
.second_sectionn_h4 {
  text-align: center;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 56px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;
}
.join_the_largest_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* line-height: 49px; */
  text-transform: capitalize;

  color: #3a3a3a;
}
.speend_million {
  background-color: #edac3e;
  height: 400px;
}
.hbibi_img {
  position: absolute;
}
.delivers_ne_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  width: 80%;
  line-height: 49px;
  text-transform: capitalize;
  color: #464646;
}
.delivers_ne_h55 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  width: 80%;
  line-height: 42px;
  /* or 168% */
  text-transform: capitalize;
  color: #ffffff;
}
.delivers_ne_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  width: 80%;
  line-height: 32px;
  /* or 178% */
  text-transform: capitalize;
  color: #ffffff;
}
.secrch_btn1.createBusiness {
  padding: 15px 50px;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 1px solid #ffffff !important;
  text-decoration: none;
  transition: all 0.5s;
}
.secrch_btn1.createBusiness:hover {
  color: #ffffff;
  background-color: #edac3e;
  border: 1px solid #ffffff !important;
}
.secrch_btn1 {
  padding: 15px 50px;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 1px solid #ffffff;
  text-decoration: none;
  transition: all 0.5s;
}
.secrch_btn1:hover {
  color: #ffffff;
  background-color: #edac3e;
  border: 1px solid #ffffff;
}
.stop_the_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  text-transform: capitalize;

  color: #111111;
}
.stop_the_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  text-transform: capitalize;
  color: #555555;
}
.atttract_clients {
  background-color: #edac3e;
}
.atttract_clients_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  text-transform: capitalize;
  color: #ffffff;
}
.card_on_file {
  background-color: white;
  height: 270px;
}
.card_on_file i {
  font-size: 40px;
  color: #687466;
}
.card_on_file_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 49px;
  text-transform: capitalize;

  color: #111111;
}
.card_on_file_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  /* or 178% */
  text-align: center;
  text-transform: capitalize;
  color: #555555;
}
.at_style {
  background-color: #edac3e;
}
.your_own_booking {
  background-color: white;
}
.your_own_booking_ull li {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 49px;
  list-style: none;
  /* or 156% */
  text-transform: capitalize;
  color: #30a55e;
}
.your_own_booking_ull li span {
  float: right;
  margin-right: 24px;
}
.eyeliner {
  height: 400px;
  width: 600px;
  background-image: url(../src/app/assets/images/eyelinerr.png);
  background-size: cover;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.eyeliner img {
  /* max-width: 100%;
    max-height: 100%; */
  display: block;
  margin: 0 auto;
}
.eyeline2 {
  height: 400px;
  width: 700px;
  background-image: url(../src/app/assets/images/eyeliner2.png);
  background-size: cover;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
/* ------------ Create Business Page End ----------------- */

/* ------------- Business Name Page Start------- */

.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}

.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.upload_imgess {
  position: relative;
}
.upload_imgess::before {
  position: absolute;
  content: "Upload img";
  color: red;
}
.box {
  height: 29px;
  width: 66px;
  background-color: #988484;
  transition: 0.5s;
  border-radius: 10px;
  /* transition: 0.7s; */
}

#btn-1 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
.toggle_btn_row {
  background-color: #fff5ec;
  padding: 25px 0px !important;
}
.toggle_btn_businessName {
  padding: 15px 0px !important;
}
.identification_businessName {
  padding-bottom: 21px;
}
.DOBAdd {
  border-bottom: 1px solid #d0d0d0 !important;
  border: none;
  background: none;
  display: block;
  margin: 1px auto;
  padding: 15px 0;
  width: 100%;
  outline: none;
  border-radius: 0px;
  text-align: left;
  transition: 250ms width ease, 250ms border-color ease;
  font-family: "Rufina";
  outline: none;
  line-height: inherit;
}
/* ------------- Business Name Page End------- */

/* ---------- My Account Page Start --------- */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}

.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}

.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}

.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}

.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}

.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}

.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}

.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}

.dropdown-item {
  color: #555555;
}

.dropdown-item:hover {
  color: black;
}

._h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}

/* navbar end */

/* sidebar left start */

.slide_bar_left {
  background-color: #f9f9f9;
  /* padding: 0px 60px; */
}

.nav-pills .editProfile .nav-link {
  background: 0 0;
  border: 0;
  text-align: left;
  color: #2e2e2e !important;
  border-radius: var(--bs-nav-pills-border-radius);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  margin-left: 0px !important;
  border: none !important;
  /* or 320% */
  text-transform: capitalize;
}

.nav-pills .editProfile .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  text-align: left;
  border-radius: 0px;
  color: white;
  width: 98% !important;
}

.nav-pills .editProfile .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #edac3e !important;
  text-align: left; 
  color: white !important;
  width: 98% !important;
  border: none;
  margin-left: 0px !important;
  border-radius: 5px !important;
}
/* .nav-link:focus .icon_navs{
    color: red !important;
} */
.nav-pills .editProfile .nav-link.active .icon_navs {
  color: rgb(255, 255, 255) !important;
}

.mail-contact {
  line-height: 10px;
}

.edit_profile_btn {
  padding: 5px 50px;
  border: 1px solid #edac3e !important;
  border-radius: 0px !important;
  color: #edac3e !important;
  /* background-color: white !important; */
  color: black;
}

.icon_navs {
  color: #edac3e;
}

.nav-link:focus .icon_navs {
  color: white !important;
}

.nav-pills .nav-link.active .nav-link,
.nav-pills .show > .icon_navs {
  color: white !important;
}

.edit_profile_h55 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 49px;
  text-transform: capitalize;
  color: #000000;
  /* margin-left: -12px !important; */
}
.contact_info_input {
  background-color: #f9f9f9;
  width: 80%;
  height: 50px;
  padding: 0px 20px;
  border: none;
  box-shadow: rgb(252, 252, 252);
  border-radius: 5px;
  font-size: 15px;
}

#light-text {
  font-size: 17px;
  margin-top: 13px;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 124px;
  height: 45px;
}

.switch input {
  visibility: hidden;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: -5px -5px 8px rgba(255, 255, 255, 0.2),
    5px 5px 8px rgba(0, 0, 0, 0.2);
}

.slider:before {
  position: absolute;
  content: "";
  height: 40px;
  width: 40px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

input:checked + .slider {
  background-color: #edac3e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #edac3e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(76px);
}

.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #edac3e;
  /* text-align: center;
    border-radius: 0px;
    width: 100%; */
  color: white !important;
}

.support_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 12px !important;
  background-color: white !important;
  padding: 10px 0px;
  /* identical to box height */
  color: rgb(32, 32, 32) !important;
  text-transform: capitalize;
}

.see_time_btn {
  transition: all 0.5s;
  padding: 10px 50px;
  background-color: #edac3e;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e;
}

.see_time_btn:hover {
  background-color: #ffffff;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: 0.5s;
}

.my_offers_btn2 {
  color: #edac3e !important;
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  height: 60px;
  transition: all 0.5s;
  text-align: left;
}

.add_promocode_input {
  width: 90%;
  height: 50px;
  background-color: #f7f7f7;
  padding: 0px 15px;
  outline-color: #edac3e;
  border: none;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}

.cancel_btn1 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}

.cancel_btn1:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.promo_button {
  color: white !important;
  background-color: #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
  cursor: pointer;
  border: none;
}

.promo_button:hover {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
}

.the_consultation_haircolorq {
  background-color: #f9f9f9;
  text-align: left;
}

.shakita_byrd2 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  background-color: #b1b1b1;
}

.card_set_up {
  background-color: #f9f9f9;
}

.input_card {
  background-color: #f9f9f9;
}

.input_card input {
  background-color: transparent;
  width: 88%;
  height: 43px;
  padding: 6px;
  outline: none;
  border: none;
  letter-spacing: 5px;
}

.atm_card {
  width: 30px;
  border: none;
  outline: none;
  background: #f9f9f9;
  color: #555555;
}

.main_input {
  background-color: #f9f9f9;
  padding: 10px;
}

.main_input input {
  background-color: transparent;
  height: 30px;
  width: 41%;
  padding: 0px 15px;
  outline: none;
  border: none;
}

.span_card {
  width: 200px;
}

.span_card input {
  width: 93px;
  float: right;
  margin: 0px 2px;
}

.name_input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(202, 202, 202) !important;
  line-height: 45px;
  font-family: "Rufina";
  outline: none;
  border-radius: 0px;
}

.name_inputtext {
  border: 1px solid #d9d9d9 !important;
  padding: 5px 18px;
  color: #7c7c7c;
}

.are_u_sure {
  background-color: #f9f9f9;
}

.nevermind_btn {
  padding: 10px 50px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e;
  transition: 0.5s;
  display: block;
}

.nevermind_btn:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: #ffffff;
  transition: 0.5s;
}

.are_u_sure h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 49px;
  text-transform: capitalize;
  color: #000000;
}

.are_u_sure p {
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-transform: capitalize;
  color: #4e4e4e;
}

.lorem_p {
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 25px;
  color: #555555;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* sidebar left end */

.profile_set_one img {
  border-radius: 100px;
}

.image-tab img {
  border-radius: 100px !important;
}

.cancel_btn11 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 10px 0px !important;
  border-radius: 0px;
  transition: all 0.5s;
  width: 170px;
  display: grid;
  /* justify-content: right; */
}

.cancel_btn11:hover {
  color: white !important;
  background-color: #edac3e !important;
}

.coppen_up {
  border-radius: 10px 0px 0px 10px;
  position: relative;
}
/* .coppen_up::after{
    position: absolute;
    content: 'public';
    color: red;
    top: 10px;
} */

.buttonzz {
  position: relative;
  padding: 8px 10px;
  border: 2px dotted black;
  font-size: 0.835em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  font-weight: bold;
  color: #000;
  background: #fff;
  transition: background 0.275s;
}

.buttonzz:hover,
.buttonzz:focus {
  background: #ea2237;
  color: #fff;
  cursor: pointer;
}

.buttonzz_1 {
  color: rgb(255, 255, 255);
  text-decoration: none;
  /* margin: 0px 10px 10px 7px !important; */
  /* font-family: 'Rufina'; */
  font-size: 17px;
  font-weight: 500;
  position: relative;
}
/* .buttonzz_1::after{
    content: 'Expiry date';
    position: absolute;
    width: 117px;
    top: -24px;
    font-weight: 600;
    left: 0px;
    color: #bfa1a1;
} */

.buttonzz1 {
  color: white;
  text-decoration: none;
  margin: 10px !important;
  margin-top: 20px;
  font-family: "Rufina";
  font-size: 19px;
  /* position: relative; */
}

.coppen_up_bg {
  background-color: #edac3e;
  border-radius: 10px;
  box-shadow: 3px 5px 15px #b3b3b3;
}

.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: 0px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 2px 7px 12px 8px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  -webkit-filter: none;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}

.share_h5 {
  text-align: center;
  font-family: "Rufina";
}

.share_icon {
  text-align: center;
  font-size: 70px;
}

.share_div_start {
  /* text-align: center; */
}

.share_div_start h5 {
  font-weight: 500;
  font-family: "Rufina";
}

/* .share_bb {
  background-color: #edac3e;
  padding: 7px 30px;
  border: 1px solid #edac3e;
  color: white;
} */

.share_b2 {
  background-color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #edac3e;
  color: #edac3e;
}

.share_btn1 {
  background-color: transparent;
  padding: 7px 11px;
  border: 1px solid #edac3e;
  color: #ffffff;
  margin-left: 7px !important;
  transition: all 0.5s;
  float: right;
}

.share_btn1:hover {
  background-color: #edac3e;
  /* padding: 7px 30px; */
  border: 1px solid #ffffff;
  color: white;
}

.coppen_up_bg h4 {
  font-family: "Rufina";
  font-weight: 600;
  font-size: 22px;
}

.coppen_up_bg p {
  /* font-family: 'Rufina'; */
  font-weight: 400;
  color: rgb(82, 82, 82);
}

.coppen_up_bg h2 {
  font-family: "Rufina";
  font-weight: 700;
}

/* icon modal start */

@import "https://fonts.googleapis.com/css?family=Playball";

.mn-social-bottom-c p {
  /* margin: -85px 0 20px; */
  text-align: center;
  /* font-family: 'Playball', cursive; */
  font-size: 25px;
  color: #5a5a5a;
}

.mn-social-bottom {
  background: #f5f5f5;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  padding: 13px 0 0;
  color: #ff822d;
  border-radius: 4px;
  margin: 0 7.5px 15px;
  transition: all 0.3s;
  font-size: 19px;
  display: inline-block;
  text-align: center;
  position: relative;
}

.mn-social-bottom:hover {
  background: #ff822d;
  color: #fff;
  top: -3px;
}

#mn-social-bottom-hidden {
  display: none;
}

.fa-plus {
  transition: -webkit-transform 0.3s;
}

.fa-plus.mn-social-r {
  -webkit-transform: rotate(45deg);
}
.from_width {
  width: 200px !important;
  height: 45px;
  font-size: 16px;
}
.form-select:focus {
  border-color: #edac3e;
  border: 2px solid #edac3e;
  outline: 0;
  box-shadow: none;
}

/* icon modal end */

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #ecab3d;
  background-color: #565e6400;
  border-color: #51585e00;
  /* font-size: 22px; */
}
.dropdown-toggle::after {
  display: none;
}
i.fa-solid.fa-list-ul {
  font-size: 24px;
  color: rgb(68, 68, 68);
}
.dropdown_22 {
  float: right;
  /* margin-left: 145px; */
}
.dropdown_menu_2 {
  /* background-color: #EDAC3E; */
}
.coppen_up_bg_2 {
  background-color: #d9d9d9 !important;
  border-radius: 10px;
}

.coppen_up_bg_2 h4 {
  font-family: "Rufina";
  font-weight: 600;
  font-size: 22px;
}

.coppen_up_bg_2 p {
  /* font-family: 'Rufina'; */
  font-weight: 400;
  color: white;
}

.coppen_up_bg_2 h2 {
  font-family: "Rufina";
  font-weight: 700;
}
.dott_icon {
  font-size: 14px;
  margin-right: 2px;
}
.edit_icon {
  margin-right: 2px;
}
.dlete_icon {
  /* color: red; */
  margin-right: 2px;
}
.catagory_name {
  color: #000000 !important;
  font: 18px;
  font-weight: 600;
  background: #ffffff;
  /* height: 100%; */
  margin-top: 5px;
  position: relative;
  /* top: 4px; */
  padding: 2px 18px;
  /* height: 26px; */
  border-radius: 3px;
}

.cancel_btn12 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 10px 0px !important;
  border-radius: 0px;
  transition: all 0.5s;
  width: 170px;
  display: grid;
  margin-top: -53px;
  /* justify-content: right; */
}

.cancel_btn12:hover {
  color: white !important;
  background-color: #edac3e !important;
}

.address_p {
  line-height: 20px;
  font-size: 15px;
  color: rgb(56, 56, 56);
}
.address_home {
  background-color: red;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
}
.address_p_1 {
  font-size: 17px;
}
.defulat_ad {
  color: #4e4e4e;
  margin-left: -10px;
}

/* ------- My Account Page End -------- */

/* ------Favourite Page Start ----------- */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e !important;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.nav-pills .nav-link {
  border: 0.954545px solid #edac3e !important;
  border-radius: 0px !important;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

.nav-pills .nav-link.favourite.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 12px 56px !important;
  border-radius: 0px !important;
}
.nav-pills .nav-link.favourite {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #edac3e;
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 0px;
  /* margin: 10px 10px; */
}
.nav-pills .nav-link.favourite:nth-child(2) {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #edac3e;
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 0px;
  /* margin: 10px 10px !important; */
}
.hair_and_makeup_card.favouite {
  height: auto;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.144);
  margin-bottom: 10px;
}
.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
}
.hair_and_makeup_card_1_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
}
.hair_and_makeup_card_h55 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  color: #edac3e;
  line-height: 20px;
}
.star_pettren i {
  color: #edac3e;
  font-size: 13px;
}
.uneek {
  color: #bebebe !important;
}
.star_pettren_span {
  color: #666666;
  font-size: 14px;
}
.woman_cut_left {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_right {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px !important;
  color: #666666;
}
.woman_cut_h66 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-top: 1px;
}

.give_rating {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  padding: 5px 15px;
  transition: all 0.5s;
}
.give_rating:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}
.star_pettrenn i {
  color: #edac3e;
  font-size: 20px;
}
.add_rating {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 56px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #2e2e2e;
}
.textarea_set {
  border: 1px solid #afafaf;
  color: #a1a1a1;
  font-size: 15px;
}
.modal-dialog {
  height: 80% !important;
  padding-top: 5%;
}
.modal-body {
  height: 80%;
  overflow: auto;
}
/* card */

.card_buy {
  height: auto;
  /* background-color: red; */
  box-shadow: 1px 1px 5px #9b9a9aad;
}
.card_present {
  /* height: 20px;
    width: 50px; */
  background-color: #edac3e;
  font-size: 12px;
  color: white;
  padding: 5px 10px;
}
.cosdrem_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.star_pettren1 i {
  color: #edac3e;
  font-size: 15px;
}
.star_pettren1 {
  margin-top: -12px;
}
.money_bills_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* or 169% */
  color: #edac3e;
}
.money_bills_p span {
  color: #727272;
}
.give_rating1 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  color: white;
  padding: 10px 15px;
  width: 200px;
  text-decoration: none;
  transition: all 0.5s;
  /* margin: 0px 10px; */
}
.give_rating1:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

/* card */

/* ----------- Favorite Page End --------- */

/* --------- My Appointments Page Start ---------------- */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

.nav-pills .nav-link.active.a3,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e;
  padding: 12px 56px;
  border-radius: 0px;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #edac3e;
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 0px;
}

.nav-pills .nav-link:nth-child(2) {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  /* border: 1px solid #EDAC3E; */
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 5px;
  /* margin: 10px 10px !important; */
}

.hair_and_makeup_card.myAppointment {
  height: auto;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.144);
  margin-bottom: 10px;
}
.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
}

.star_pettren i {
  color: #edac3e;
  font-size: 13px;
}
.uneek {
  color: #bebebe !important;
}
.star_pettren_span {
  color: #666666;
  font-size: 14px;
}
.woman_cut_left {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_right {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #666666;
}

.give_rating {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  padding: 5px 15px;
  transition: all 0.5s;
}
.give_rating:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}
.star_pettrenn i {
  color: #edac3e;
  font-size: 20px;
}
.add_rating {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 56px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #2e2e2e;
}
.textarea_set {
  border: 1px solid #afafaf;
  color: #a1a1a1;
  font-size: 15px;
}
.modal-dialog {
  height: 80% !important;
  padding-top: 5%;
}

.modal-body {
  height: 80%;
  overflow: auto;
}

/* --------- My Appointments Page End ---------------- */

/* ---------My Order Start ------------------ */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.nav-pills .nav-link.order.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 12px 56px;
  border-radius: 0px !important;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #edac3e;
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 0px;
  /* margin: 10px 10px; */
}
.nav-pills .nav-link:nth-child(2) {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #edac3e;
  padding: 12px 56px;
  color: #edac3e;
  border-radius: 0px;
  /* margin: 10px 10px !important; */
}
.ongoing_section {
  background-color: #f7f7f7;
}
.ongoing_section_right h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  /* or 144% */
  text-transform: capitalize;
  color: #2e2e2e;
}
.ongoing_section_right h4 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  /* identical to box height */

  text-transform: uppercase;

  color: #edac3e;
}
.accordion-button {
  position: relative;
}
.accordion-button.myorder:not(.collapsed) {
  color: white;
  background-color: #edac3e !important;
  box-shadow: none;
  border-radius: 0px !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #edac3e;
  outline: 0;
  box-shadow: none;
}

.accordion-button.myorder:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
  filter: brightness(29.5);
}
.accordion-item:last-of-type.myorder .accordion-button.collapsed {
  border-bottom-right-radius: var(
    --bs-accordion-inner-border-radius
  ) !important;
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius) !important;
  /* filter: brightness(29.5); */
}
.accordion-item:last-of-type.myorder .accordion-button.collapsed {
  color: white;
  background-color: #edac3e !important;
}
.accordion-button.myorder {
  background-color: #edac3e !important;
  color: white !important;
}
.accordion-button::after {
  filter: brightness(29.5) !important;
}
.accordion-item:last-of-type.myorder .accordion-button.collapsed {
  border-radius: 0px !important;
}
.accordion-button::after {
  filter: brightness(23233) !important;
  color: red;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* progress bar start */

.hh-grayBox {
  background-color: #f8f8f8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}
.pt45 {
  padding-top: 45px;
}
.order-tracking {
  text-align: center;
  width: 20%;
  position: relative;
  display: block;
}
.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #afafaf;
  background-color: #a4a4a4;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #afafaf;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.order-tracking.completed .is-complete {
  border-color: #edac3e;
  border-width: 0px;
  background-color: #edac3e;
}
.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
.order-tracking p {
  color: #a4a4a4;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}
.order-tracking p span {
  font-size: 14px;
}
.order-tracking.completed p {
  color: #000;
}
.order-tracking::before {
  content: "";
  display: block;
  height: 3px;
  width: calc(113% - 40px);
  background-color: #a4a4a4;
  top: 13px;
  position: absolute;
  left: calc(-50% + 15px);
  z-index: 0;
}
.order-tracking:first-child:before {
  display: none;
}
.order-tracking.completed:before {
  background-color: #edac3e;
}

.order-tracking.completed:before {
  /* width: 0px; */
  animation-name: avii;
  animation-duration: 5s;
}

@keyframes avii {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
.order-tracking.completed .is-complete {
  animation-name: aviia;
  animation-duration: 5s;
}
@keyframes aviia {
  from {
    background-color: #a4a4a4;
  }
  to {
    background-color: #edac3e;
  }
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px !important;
  padding: 17px 14px;
  background-color: #edac3e !important;
  /* opacity: 0; */
  color: white;
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 0px !important;
  padding: 17px 14px;
  font-size: 17px;
}
.pt45 {
  padding-top: 45px;
  display: block;
  margin: auto;
}
.accordion-body {
  background-color: #f9f9f9;
}

/* preogress bar end */

.fa-circle-check {
  color: #edac3e;
  font-size: 25px;
}
.give_rating {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  padding: 7px 15px;
  transition: all 0.5s;
}
.give_rating:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}
.textarea_set {
  border: 1px solid #afafaf;
  color: #a1a1a1;
  font-size: 15px;
}
.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
}

.add_rating {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
  color: #2e2e2e;
}
.star_pettrenn i {
  color: #edac3e;
  font-size: 20px;
}
.give_rating1 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  color: white;
  padding: 10px 15px;
  width: 200px;
  text-decoration: none;
  transition: all 0.5s;
  /* margin: 0px 10px; */
}
.give_rating1:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

/* --------My Order Page End */

/* ----------- Add To Card Start Page ----------- */


.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.ongoing_section_right h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  text-transform: capitalize;
  color: #2e2e2e;
}
.ongoing_section_right h4 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  text-transform: uppercase;
  color: #edac3e;
}
.ongoing_section {
  background-color: #f9f9f9;
  position: relative;
}
.delete_icon {
  color: red;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
.sub_total {
  background-color: #f9f9f9;
}
.sub_total_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* identical to box height */
  color: #2e2e2e;
}
.sub_total_p span {
  float: right;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  color: #2e2e2e;
}
.sub_total_pp {
  /* position: absolute; */
  left: 65.78%;
  right: 31.46%;
  top: 33.85%;
  bottom: 64.94%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #edac3e;
}
.sub_total_pp span {
  float: right;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  color: #2e2e2e;
  color: #edac3e;
}
.apply_shop_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 80px;
  /* identical to box height, or 320% */
  color: #edac3e;
}
.order_gif_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  color: #2e2e2e;
}
.order_gif_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */
  color: #767676;
}
.order_gif_area {
  width: 100%;
  background-color: transparent;
}
.proceed_to_pay a {
  background-color: #edac3e;
  padding: 10px 30px;
  border: 1px;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  border: 1px solid #edac3e;
}
.proceed_to_pay a:hover {
  background-color: white;
  color: #edac3e;
  border: 1px solid #edac3e;
}
.proceed_to_pay {
  float: right;
}

.free_contant {
  background: #f7f7f7;
  padding: 75px 0px;
  text-align: center;
  margin-top: 24px;
  /* font-size: 20px; */
}

.btn-close {
  box-sizing: content-box;
  width: 9px;
  height: 6px;
  margin-right: 6px !important;
  /* padding-right: 20px !important; */
  padding: 0.25em 0.25em !important;
  color: #ededed;
  /* background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat; */
  border: 0;
  filter: invert(10);
  border-radius: 0.375rem;
  opacity: 1 !important;
}
.modal-footer1 {
  display: block;
  border: none;
}
.send_btn1 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.send_btn1:hover {
  background-color: white !important;
  color: #edac3e !important;
}
.cancel_btn1 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.cancel_btn1:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.modal_input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-top: 20px !important;
  box-shadow: 0px 0px 5px lightgrey;
  color: #747474;
}
.the_consultation_haircolor_h5_modal {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 27px !important;
}
.the_consultation_haircolor_p12 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 10px !important;
  color: #edac3e !important;
}
.the_consultation_haircolor_p23 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 25px !important;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
  margin-top: -10px !important;
}
.modal_height2 {
  height: 70% !important;
  overflow-x: scroll;
  padding: 0px !important;
}
.send_btn12 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.single_arrow_rotate {
  transform: rotate(90deg) !important;
  margin: 10px;
}
.add_services_btn {
  padding: 10px 30px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.add_services_btn:hover {
  background-color: white;
  color: #edac3e;
}

.my_offers_btn2 {
  color: #edac3e !important;
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}
.modal-header12 {
  background-color: #edac3e;
  border-radius: 0px;
  text-align: center;
  display: block !important;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 27px;
}
.update_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */
  text-transform: capitalize;
}
.checkbox_z {
  width: 17px;
  position: relative;
  top: 9px;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type="checkbox"]:after {
  box-shadow: none !important;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}

.coppen_up_bg {
  background-color: #edac3e;
  border-radius: 10px;
  box-shadow: 3px 5px 15px #b3b3b3;
}
.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: 0px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 2px 7px 12px 8px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  -webkit-filter: none;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}
.share_h5 {
  text-align: center;
  font-family: "Rufina";
}
.share_icon {
  text-align: center;
  font-size: 70px;
}
.share_div_start {
  /* text-align: center; */
}
.share_div_start h5 {
  font-weight: 500;
  font-family: "Rufina";
}
/* .share_bb {
  background-color: #edac3e;
  padding: 7px 30px;
  border: 1px solid #edac3e;
  color: white;
} */
.share_b2 {
  background-color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #edac3e;
  color: #edac3e;
}
.share_btn1 {
  background-color: transparent;
  padding: 7px 11px;
  border: 1px solid #edac3e;
  color: #ffffff;
  margin-left: 7px !important;
  transition: all 0.5s;
  float: right;
}
.share_btn1:hover {
  background-color: #edac3e;
  /* padding: 7px 30px; */
  border: 1px solid #ffffff;
  color: white;
}
.coppen_up_bg h4 {
  font-family: "Rufina";
  font-weight: 600;
  font-size: 22px;
}
.coppen_up_bg p {
  /* font-family: 'Rufina'; */
  font-weight: 400;
  color: rgb(82, 82, 82);
}
.coppen_up_bg h2 {
  font-family: "Rufina";
  font-weight: 700;
}
/* icon modal start */
@import "https://fonts.googleapis.com/css?family=Playball";
.mn-social-bottom-c p {
  /* margin: -85px 0 20px; */
  text-align: center;
  /* font-family: 'Playball', cursive; */
  font-size: 25px;
  color: #5a5a5a;
}
.mn-social-bottom {
  background: #f5f5f5;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  padding: 13px 0 0;
  color: #ff822d;
  border-radius: 4px;
  margin: 0 7.5px 15px;
  transition: all 0.3s;
  font-size: 19px;
  display: inline-block;
  text-align: center;
  position: relative;
}
.mn-social-bottom:hover {
  background: #ff822d;
  color: #fff;
  top: -3px;
}
#mn-social-bottom-hidden {
  display: none;
}
.fa-plus {
  transition: -webkit-transform 0.3s;
}
.fa-plus.mn-social-r {
  -webkit-transform: rotate(45deg);
}
.from_width {
  width: 200px !important;
  height: 45px;
  font-size: 16px;
}
.form-select:focus {
  border-color: #edac3e;
  border: 2px solid #edac3e;
  outline: 0;
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none;
}
i.fa-solid.fa-list-ul {
  font-size: 24px;
  color: rgb(68, 68, 68);
}
.dropdown_22 {
  float: right;
  /* margin-left: 145px; */
}
.dropdown_menu_2 {
  /* background-color: #EDAC3E; */
}
.coppen_up_bg_2 {
  background-color: #d9d9d9 !important;
  border-radius: 10px;
}
.coppen_up_bg_2 h4 {
  font-family: "Rufina";
  font-weight: 600;
  font-size: 22px;
}
.coppen_up_bg_2 p {
  /* font-family: 'Rufina'; */
  font-weight: 400;
  color: white;
}
.coppen_up_bg_2 h2 {
  font-family: "Rufina";
  font-weight: 700;
}
.dott_icon {
  font-size: 14px;
  margin-right: 2px;
}
.edit_icon {
  margin-right: 2px;
}
.dlete_icon {
  /* color: red; */
  margin-right: 2px;
}
.catagory_name {
  color: #000000 !important;
  font: 18px;
  font-weight: 600;
  background: #ffffff;
  /* height: 100%; */
  margin-top: 5px;
  position: relative;
  /* top: 4px; */
  padding: 2px 18px;
  /* height: 26px; */
  border-radius: 3px;
}
.cancel_btn12 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 10px 0px !important;
  border-radius: 0px;
  transition: all 0.5s;
  width: 170px;
  display: grid;
  margin-top: -53px;
  /* justify-content: right; */
}
.cancel_btn12:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.address_p {
  line-height: 21px;
  font-size: 15px;
  color: rgb(56, 56, 56);
}
.address_home {
  background-color: red;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
}
.address_p_1 {
  font-size: 17px;
}
.defulat_ad {
  color: #4e4e4e;
  margin-left: -10px;
}

/* ------Add To Card End Page ---------- */

/* ----- After Login Page Start --------- */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}

.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}

ul.dropdown-menu.show {
  width: 217px;
}

.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}

.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}

.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}

.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}

.dropdown-item {
  color: #555555;
}

.dropdown-item:hover {
  color: black;
}

.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.atttract_clients {
  background-color: #edac3e;
}

.atttract_clients_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  text-transform: capitalize;
  color: #ffffff;
}

.card_on_file {
  background-color: #edac3e;
  height: 350px;
}

.card_on_file i {
  font-size: 40px;
  color: #ffffff;
}

.card_on_file_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 25px;
  line-height: 49px;
  text-transform: capitalize;

  color: #ffffff;
}

.card_on_file_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  /* or 178% */
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.secrch_btn1 {
  padding: 13px 50px;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 1px solid #ffffff;
  text-decoration: none;
  transition: all 0.5s;
  border-radius: 0px !important;
}

.secrch_btn1:hover {
  color: #ffffff !important;
  background-color: #edac3e !important;
  border: 1px solid #ffffff !important;
}

.ask_for_add_review_afterLogin {
  background-color: #edac3e !important;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px !important;
}

.ask_for_add_review_upper_afterLogin {
  float: right;
  margin-top: -78px !important;
  margin-right: -114px;
}

.active_list {
}

.active_list_in {
  position: relative;
  height: auto;
  box-shadow: 0px 4px 99px rgba(0, 0, 0, 0.1);
  top: -100px;
  background-color: white;
}

.you_have_h1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  padding-top: 14px;
  line-height: 63px;
  /* or 140% */
  text-transform: capitalize;
  color: #585858;
}

.fa-clock {
  color: #edac3e;
  font-size: 40px;
  text-align: center;
}

.add_profile {
  background-color: #f9f9f9;
  height: 150px;
  border-radius: 100px;
  width: 150px;
  box-shadow: 1px 2px 16px #d7d0d0;
}

/* #img_url {
    background: #ddd;
    width:100px;
    height: 90px;
    display: block;
  }

  #list img{
    width: auto;
    height: 100px;
    margin: 10px ;
  } */
.file_input_wrap {
  position: relative;
}

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  top: 46px;
  border: none;
  box-shadow: none !important;
  z-index: 2;
}

/* .btn {
    border: none !important;
} */

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin: 10px 12px;
  border-radius: 4px;
}

.hide {
  display: none;
}

/* .btn {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 10px #eee;
    border-radius: 4px;
  } */

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#imagePreview {
  /* margin: 15px 0 0 0; */
  border: 2px solid #ddd;
}

#imagePreview {
  /* margin: 15px 0 0 0; */
  border: 2px solid #ddd;
  width: 150px;
  height: 150px;
  border-radius: 100px;
}

.janae_mille_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height, or 120% */
  color: #2e2e2e;
}

.janae_mille_h5 span {
  color: #edac3e;
}

.professional_left {
  background-color: #f9f9f9;
}

.professional_right {
  background-color: #f9f9f9;
}

.professional_left h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 153% */
  color: #edac3e;
}

.professional_left p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
  /* identical to box height */
  color: #555555;
}

.professional_right h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 153% */
  color: #edac3e;
}

.professional_right p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
  /* identical to box height */
  color: #555555;
}

.star_icons {
  color: #dedede;
}

.add_photo_12 {
  height: 110px;
  cursor: pointer;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 4px #e9e5e5;
}

.add_photo_12 i {
  color: #edac3e;
  text-align: center;
}

.add_photo_12 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}
.add_photo_2.az {
  height: 144px !important;
  cursor: pointer;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 4px #e9e5e5;
}
.add_photo_2.az i {
  margin-top: -8px !important;
}

.add_photo_2 {
  height: 90px;
  cursor: pointer;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 4px #e9e5e5;
  margin-bottom: 32px;
}


.add_photo_2 i {
  color: #edac3e;
  text-align: center;
}

.add_photo_2 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}
i.fa-solid.fa-image.pt-5 {
  margin-top: -36px;
}
.container.at {
  width: 85% !important;
}
/* .min_span {
  background-color: red;
  padding: 4px 5px 4px 6px;
  border-radius: 100%;
  color: white;
  position: relative !important;
  left: 87px;
  top: 14px;
} */
.min_span{
  background-color: red;
  padding: 3px 7px 2px 8px;
  border-radius: 100%;
  color: white;
  position: absolute;
  left: 241px;
  /* top: 42px; */
  margin-top: -20px;
}

.get_reviews {
  background-color: #fefbf8;
}

.fa-star-half-stroke {
  color: #edac3e;
  font-size: 28px;
}

.get_reviews_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  /* identical to box height */
  text-transform: capitalize;
  color: #edac3e;
}

.get_reviews_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  /* identical to box height, or 171% */
  color: #555555;
}

.ask_for_add_review {
  background-color: #edac3e !important;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px !important;
}

.ask_for_add_review:hover {
  background-color: white !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

.ask_for_add_review_upper {
  float: right;
  margin-top: -50px !important;
}

@media screen and (min-width: 320px) and (max-width: 1140px) {
  .ask_for_add_review_upper {
    float: left;
    margin-top: 0px !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #edac3e !important;
  background-color: transparent !important;
  border: none;
  border-bottom: 2px solid #edac3e;
  border-radius: 0;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #afafaf !important;
  font-size: 17px;
  margin: 0px 8px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.shakita_byrd {
  height: 489px;
  /* background-color: red; */
}

.set_up_prices_services {
  background-color: #f9f9f9;
  padding: 100px 0px;
}

.set_up_prices_services a {
  width: 300px;
  text-align: center;
}

.set_up_prices_services1 {
  padding: 50px 0px;
}

.set_up_prices_services1 a {
  width: 300px;
  text-align: center;
}

a.btn.secrch_btn12 {
  border-radius: 0px;
}

.secrch_btn12 {
  padding: 14px 67px;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 1px solid #ffffff;
  text-decoration: none;
  transition: all 0.5s;
}

.secrch_btn12:hover {
  color: #ffffff !important;
  background-color: #edac3e !important;
  border: 1px solid #ffffff !important;
}

.build_your_service_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  /* line-height: 56px; */
  /* identical to box height */
  text-transform: capitalize;
  color: #222222;
}

.build_your_service_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  /* line-height: 31px; */
  /* identical to box height */
  text-transform: capitalize;
  color: #555555;
}

.modal-header {
  border: none;
}

.fa-file-lines {
  color: #edac3e;
  font-size: 57px;
  margin-bottom: 10px;
}

.modal-content {
  border-radius: 0px;
}

.select_btn {
  border: none;
  border-bottom: 1px solid #bfbfbf;
  width: 100%;
  background-color: white;
  outline: none;
  /* line-height: 74px !important; */
  color: #707070;
}

label {
  display: inline-block;
  color: #3e3e3e;
}

.modal-footer {
  border: none;
}

.ask_for_add_review2 {
  background-color: #edac3e;
  padding: 10px 80px;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
  /* margin-top: 0px !important; */
  margin-left: 15px;
}

.ask_for_add_review2:hover {
  background-color: white !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

.ask_for_add_review3 {
  background-color: #ffffff;
  padding: 10px 20px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
}

.ask_for_add_review3:hover {
  background-color: #edac3e !important;
  color: #ffffff !important;
  border: 1px solid #f8f8f8 !important;
}


.wade_a {
  background-color: #edac3e;
}

.wade_a_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* identical to box height */
  text-transform: capitalize;
  color: #ffffff;
}

.wade_a_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-transform: capitalize;
  color: #ffffff;
}

.service_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 56px;
  /* identical to box height */
  text-transform: capitalize;
  color: #ffffff;
}

.service_h5 span {
  float: right;
}

.bbbby.text-center {
  overflow-y: scroll;
  height: 416px !important;
  padding: 26px !important;
}
.form123 {
  text-align: left !important;
  width: 100% !important;
}
.bbbby3 {
  overflow-y: scroll;
  height: 511px !important;
  padding: 26px !important;
}
.wade_a {
  background-color: #edac3e;
  height: 310px;
}

.consultation_ul li {
  list-style: none;
  color: white;
}

.consultation_ul li span {
  float: right;
}

.consultation_ul {
  margin-left: -26px;
}

.the_consultation_haircolor_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.the_consultation_haircolor_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 13px;
  padding-top: 5px;
  text-transform: capitalize;
  color: #edac3e;
}

.the_consultation_haircolor_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  /* or 181% */
  text-transform: capitalize;
  color: #5a5a5a;
}

.see_time_btn {
  padding: 10px 10px !important;
  background-color: #edac3e !important;
  width: 163px;
  height: 46px;
  color: white;
  text-decoration: none;
  transition: 0.5s;
  border-radius: 0px;
  border: 1px solid #edac3e !important;
}

.see_time_btn:hover {
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

.more_info_btnn {
  /* padding: 10px 50px !important; */
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
  text-decoration: none;
  width: 163px;
  height: 46px;
  transition: all 0.5s;
  border-radius: 0px;
}

.more_info_btnn:hover {
  background-color: #edac3e !important;
  color: white !important;
  text-decoration: none;
}

.the_consultation_haircolor {
  background-color: #f9f9f9;
  /* background-color: #f4f4f4; */
}

.the_consultation_haircolor_in {
  background-color: white;
}

.service_name_input {
  border: none;
  border-bottom: 1px solid rgb(207 207 207);
  color: #646464 !important;
  width: 88%;
  line-height: 39px;
  font-size: 15px;
  outline: none;
  height: 50px !important;
  background-color: transparent !important;
  
}
.service_name_textarea {
  border: none;
  border-bottom: 1px solid rgb(207 207 207);
  color: #646464 !important;
  width: 88%;
  line-height: 39px;
  font-size: 15px;
  outline: none;
  /* height: 50px !important; */
  background-color: transparent !important;
}

.let_clients_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #a0a0a0;
}

.ask_for_add_review32 {
  background-color: #ffffff;
  padding: 10px 20px;
  color: red;
  text-decoration: none;
  border: 1px solid red !important;
  transition: all 0.5s;
  border-radius: 0px;
}

.ask_for_add_review32:hover {
  background-color: red !important;
  color: #ffffff !important;
  border: 1px solid red !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: #edac3e !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
  filter: brightness(0.5);
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none;
}

.accordion-button {
  background-color: #f0f0f0 !important;
}

.accordion-item {
  margin: 14px 0px;
  border-radius: 0px !important;
}

.accordion_ul li {
  list-style: none;
  color: #555555;
}

.accordion_ul li span {
  float: right;
  /* border-radius: 100px; */
  /* border: 1px solid black; */
}

.accordion_ul {
  margin-left: -25px;
}

.upload_from {
  background-color: #fff5ec;
  padding: 40px 0px;
}

.fa-image {
  font-size: 35px;
  color: #edac3e;
}

.upload_from_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  /* identical to box height */
  text-transform: capitalize;
  color: #222222;
}

.box {
  height: 29px;
  width: 66px;
  background-color: #988484;
  transition: 0.5s;
  border-radius: 10px;
  /* transition: 0.7s; */
}

#btn-1 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
.toggle_btn_row {
  background-color: #fff5ec;
  padding: 25px 0px !important;
}
.toggle_btn {
  background-color: #fff5ec;
  padding: 15px 15px !important;

  width: 89%;
}
.toggle_btn_11 {
  background-color: white !important;
  padding: 0px;
}
.accordion_button_1 option {
  background-color: white;
  border-bottom: 1px solid rgb(172, 172, 172);
}
.accordion_button_2 option{
  color: black !important;
}

.service_name_inputt {
  border: none;
  border-bottom: 1px solid rgb(207 207 207);
  color: #646464 !important;
  background-color: transparent;
  line-height: 25px;
  width: 88%;
  line-height: 39px;
  font-size: 15px;
  padding-bottom: 10px;
  padding-left: 0 !important;
  outline: none;
}

.upload_image_right_modal {
  /* background-color: #f7f7f7; */
  /* height: 200px;
  width: 200px; */

  width: 59.5%;
  padding-top: 0px;
}

.base {
  background-color: #fff8ec;
  height: 375px !important;
}

.base_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  color: #edac3e;
}
.base_ul li {
  list-style: none;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #edac3e;
  width: 91%;
}

.base_month {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #edac3e;
}
ul.base_ul {
  margin: 0 -28px;
}
.pro {
  background-color: #edac3e;
  height: 375px !important;
}
#post_show {
  display: none;
}
/* card */

.card_buy {
  height: auto;
  /* background-color: red; */
  box-shadow: 1px 1px 5px #9b9a9aad;
}
.card_present {
  /* height: 20px;
    width: 50px; */
  background-color: #edac3e;
  font-size: 12px;
  color: white;
  padding: 5px 10px;
}
.cosdrem_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.star_pettren1 i {
  color: #edac3e;
  font-size: 15px;
}
.star_pettren1 {
  margin-top: -12px;
}
.money_bills_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* or 169% */
  color: #edac3e;
}
.money_bills_p span {
  color: #727272;
}
.give_rating1 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  color: white;
  padding: 10px 15px;
  width: 200px;
  text-decoration: none;
  transition: all 0.5s;
  /* margin: 0px 10px; */
}
.give_rating1:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

/* card */

/* service nav */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

/* navbar end */

.hair_and_makeup_card.Appointments {
  height: auto;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.144);
  margin-bottom: 15px;
}
.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 30px;
}

.star_pettren i {
  color: #edac3e;
  font-size: 13px;
}
.uneek {
  color: #bebebe !important;
}
.star_pettren_span {
  color: #666666;
  font-size: 20px;
}
.woman_cut_left {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_right {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  padding-top: 5px;
  line-height: 8px;
  color: #666666;
}

.rebond_img {
  position: relative;
}
.rebond_img_dlete {
  position: absolute;
  right: 0;
  background-color: #edac3e;
  padding: 4px 7px 4px 7px;
  margin: 5px;
  border-radius: 100px;
}
@media screen and (min-width: 320px) and (max-width: 470px) {
  #br_hide {
    display: block !important;
    /* margin-top: 20px !important; */
  }
  .view_more_btn1 {
    margin-top: 40px !important;
  }
  .star_pettren {
    text-align: center !important;
  }
  .hair_and_makeup_card_h5{
    text-align: center !important;
  }
}

.rebond_img img {
  height: 145px;
  object-fit: cover;
}
@media screen and (min-width: 320px) and (max-width: 776px) {
  .rebond_img img {
    height: auto !important;
  }
}
.rebond_view {
  height: 145px;
  width: 100%;
  background-image: url(../src/app/assets/images/rebon3.png);
  /* filter: brightness(0.4); */
  background-size: cover;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: red; */
}
.rebond_view:hover {
  cursor: pointer;
}
.rebond_view h5 {
  color: white;
  text-align: center;
  padding-top: 60px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.1818px;
  line-height: 21px;
  text-transform: capitalize;
}
#view_all_rebond {
  display: none;
}
.limited_availability {
  background-color: #fefbf8;
}
.fa-stopwatch {
  color: #edac3e;
  font-size: 35px;
}
.limited_availability h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
}
.limited_availability p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #666666;
}

/* nav and tabs start */
.nav-pills .nav-link.active.a1,
.nav-pills .show > .nav-link {
  color: #edac3e;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #edac3e;
  border-radius: 0;
}
.nav-pills .nav-link.active.a2,
.nav-pills .show > .nav-link {
  background-color: #edac3e;
  text-align: left;
  border-radius: 0px;
  color: white;
  /* width: 100% !important; */
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #afafaf !important;
  font-size: 17px;
  margin: 0px 8px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/* service navs start */
.the_consultation {
  background-color: #f9f9f9;
}
.the_consultation_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.the_consultation_haircolor {
  /* background-color: #FFFFFF; */
  background-color: #f4f4f4;
}
.the_consultation_haircolor_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.the_consultation_haircolor_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 13px;
  padding-top: 5px;
  text-transform: capitalize;
  color: #edac3e;
}
.the_consultation_haircolor_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  /* or 181% */
  text-transform: capitalize;
  color: #5a5a5a;
}
.see_time_btn {
  /* padding: 10px 50px; */
  background-color: #edac3e;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e;
}
.see_time_btn:hover {
  background-color: #ffffff;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: 0.5s;
}
.more_info_btnn {
  /* padding: 10px 50px; */
  background-color: #ffffff;
  border: 1px solid #edac3e;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.more_info_btnn:hover {
  background-color: #edac3e;
  color: white;
  text-decoration: none;
}
.shakita_byrd {
  height: 489px;
  /* background-color: red; */
}
.shakita_byrd2 {
  background-color: red;
}
.shakita_byrd2 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
}
.shakita_byrd2 p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #5a5a5a;
}
.message_btn {
  padding: 10px 50px !important;
  background-color: #edac3e !important;
  text-decoration: none;
  color: white;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
}
.message_btn:hover {
  background-color: white !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}
.business_hours {
  background-color: #f9f9f9;
  border: 1px solid #dbdbdb;
}
.time_right_side_sett {
  float: right;
  color: #5a5a5a;
  font-size: 15px;
}
.business_hours h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.business_hours p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  color: #272727;
}
.shakita_cancellation h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  text-align: center;
}
.shakita_cancellation p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #5a5a5a;
}
.shakita_cancellation h6 {
  text-align: center;
}
.facebook_i {
  padding: 13px 10px;
  background-color: #edac3e;
  border-radius: 100px;
  height: 44px;
  color: white;
  width: 44px;
  transition: all 0.5s;
}
.icons_list li {
  display: inline-block;
  padding: 0px 4px;
}

.icons_list {
  margin-left: -37px;
}
.facebook_i:hover {
  color: #edac3e;
  border: 1px solid #edac3e;
  transform: rotate(360deg);
  background-color: white;
}

/* service navs end */

/* review navs start */
.star_icon_yellow {
  color: #edac3e;
}
.star_icon_yellow1 {
  font-size: 18px;
  color: #edac3e;
  margin-top: -10px;
}
.progress-bar {
  background-color: #edac3e !important;
  font-weight: 800;
  padding: 9px 0px;
}
.progress {
  width: 76%;
}
#view_all_rebond1 {
  display: none;
}
.star_icon_yellow2 {
  font-size: 14px;
  color: #edac3e;
  padding-top: -10px;
}
.the_consultation_haircolor_p1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
}
.the_consultation_haircolor_p2 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
}
.the_consultation_h51 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.the_consultation_p1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  /* identical to box height, or 171% */
  color: #555555;
}
.the_consultation_pera {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  /* or 171% */
  color: #555555;
}
/* review navs end */

/* products navs start */

.the_consultation_ul li {
  list-style: none;
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 31px;
  color: #555555;
}
.the_consultation_ul {
  margin-left: -30px;
}
.bottom_btn {
  position: absolute;
  bottom: 30px;
}
.the_consultation_p2 {
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 31px;
  /* or 171% */
  color: #555555;
}

/* products navs end */
.nav-pills .nav-link:hover {
  color: #edac3e;
}

/* message modal start */

.modal-dialog {
  height: 80% !important;
  padding-top: 0%;
}

.modal-body {
  height: 80%;
  overflow: auto;
}

.modal-footer1 {
  display: block;
  border: none;
}
.send_btn1 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.send_btn1:hover {
  background-color: white !important;
  color: #edac3e !important;
}
.cancel_btn1 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.cancel_btn1:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.modal_input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-top: 20px !important;
  box-shadow: 0px 0px 5px lightgrey;
  color: #747474;
}
.the_consultation_haircolor_h5_modal {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 27px !important;
}
.the_consultation_haircolor_p12 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 10px !important;
  color: #edac3e !important;
}
.the_consultation_haircolor_p23 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 25px !important;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
  margin-top: -10px !important;
}
.modal_height2 {
  height: 70% !important;
  overflow-x: scroll;
}
.send_btn12 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}

.single_arrow_rotate {
  transform: rotate(90deg) !important;
  margin: 10px;
}
.add_services_btn {
  padding: 10px 30px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.add_services_btn:hover {
  background-color: white;
  color: #edac3e;
}

/* calender */
#calendar {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-family: "Lato", sans-serif;
}
#calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
}
#calendar_content,
#calendar_weekdays,
#calendar_header {
  position: relative;
  width: 320px;
  overflow: hidden;
  float: left;
  z-index: 10;
}
#calendar_weekdays div,
#calendar_content div {
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  color: #787878;
}
#calendar_content {
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}
#calendar_content div {
  float: left;
}
#calendar_content div:hover {
  background-color: #f8f8f8;
}
#calendar_content div.blank {
  background-color: #e8e8e8;
}
#calendar_header,
#calendar_content div.today {
  zoom: 1;
  filter: alpha(opacity=70);
  /* opacity: 0.7; */
}
#calendar_content div.today {
  color: #ffffff;
}
#calendar_header {
  width: 100%;
  height: 37px !important;
  text-align: center;
  background-color: #edac3e !important;
  font-size: 11px;
  padding: 9px 0;
  -webkit-border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  border-radius: 0px;
}
#calendar_header h1 {
  font-size: 1.5em;
  color: #ffffff;
  /* float:left; */
  /* width:70%; */
}
i[class^="icon-chevron"] {
  color: #ffffff;
  float: left;
  width: 15%;
  border-radius: 50%;
}

#calendar_content div.blank {
  background-color: #ffffff;
}

.desable_btn {
  padding: 7px 10px;
  color: #afafaf;
  border: 1px solid;
  font-size: 11px;
  background-color: white;
}
.nofty_btn {
  background-color: #edac3e !important;
  color: white !important;
  padding: 5px 7px !important;
  border-radius: 5px;
  /* margin-top: -41px !important;
        position: absolute; */
}

@media screen and (min-width: 320px) and (max-width: 405px) {
  .nofty_btn {
    position: static;
  }
}

/* calender */

.my_offers_btn {
  color: #ffffff !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

/* input {
        width: 56px;
        height: 30px;
        font-size: 12px;
        line-height: 12px;
        padding: 5px;
        border: none;
      } */
input.ccFormatMonitor {
  width: 100%;
  padding: 10px !important;
  margin: 10px;
}

input.ccFormatMonitor {
  width: 70%;
  outline: none;
  border: none;
}
.input_out_line {
  border: 1px solid black;
}

@media screen and (min-width: 320px) and (max-width: 502px) {
  input.ccFormatMonitor {
    width: 44%;
  }
}
.my_offers_btn2 {
  color: #edac3e !important;
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}
.modal-header12 {
  background-color: #edac3e;
  border-radius: 0px;
  text-align: center;
  display: block !important;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.update_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */

  text-transform: capitalize;
}
.checkbox_z {
  width: 17px;
  position: relative;
  top: 9px;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type="checkbox"]:after {
  box-shadow: none !important;
}

/* message modal end */

/* nav and tabs end */

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: -16px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 4px 9px 15px 10px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}
@media (min-width: 576px) {
  .modal-dialog .modal-xl {
    max-width: 47%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1000px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
  .modal-dialog .modal-xl {
    max-width: 47%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 576px) and (max-width: 1300px) {
  .modal-dialog .modal-xl {
    max-width: 30% !important;
    margin-right: auto;
    margin-left: auto;
  }
}
.ask_for_add_review_n {
  padding: 10px 46px !important;
}
.modal-content {
  overflow: visible;
  padding: 0px 19px 29px 17px;
}

@media (min-width: 576px) {
  .modal-dialog .modal-xllkk {
    max-width: 57% !important;
    margin-right: auto;
    margin-left: auto;
  }
}
.modal-dialog.modal-800 {
  width: 800px;
  margin: 30px auto;
}
.bbbby2.text-center {
  overflow-y: hidden;
  height: 295px;
  padding: 26px !important;
}
.bbbby2.text-center {
  overflow-y: hidden;
  height: 243px !important;
  padding: 26px !important;
}

/* footer css */
/* footer{
        position: absolute;
        width: 100%;
        top: 100px;
    } */
.footer_up {
  background-color: #fbf5ef;
  height: auto;
}
.free_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: rgb(44, 44, 44);
}
.asked_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
  .footer_add_margin{
    margin-top: 0px !important;
  }
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}
.footer_nd_list {
  /* float: right; */
}
/* footer css end */
.main_element {
  height: auto;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .top_setting {
    margin-top: -6px !important;
  }

  .top_setting1 {
    /* margin-top: -9px !important; */
  }
}
.top_setting {
  margin-top: 35px;
}
.top_setting1 {
  margin-top: 35px;
}
#btn-2 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
.sloat_start {
  background-color: #fff5ec;
  width: 89%;
}
.monday {
  color: #6e6e6e !important;
  font-size: 18px;
  margin-top: 10px;
}
#btn-3 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
#btn-4 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
#btn-5 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
#btn-6 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
#btn-7 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
#btn-8 {
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 1px -12px !important;
  box-shadow: 3px 1px 7px black;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  transition: 2s !important;
  border-radius: 100%;
}
.addd_service_margin {
  margin-top: 32px;
}
.add_products_margin {
  margin-top: 62px;
}
body {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

/* footer css */

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
  width: 80%;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
  .first_section_discover_right_img2 {
    display: none;
  }
  .footer_ullll li {
    display: block !important;
    text-align: left;
  }
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}

.footer_ullll li {
  color: rgb(190, 190, 190) !important;
  display: inline-block;
  padding: 0px 50px;
}
/* footer css end */

.btn_back {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  margin-right: -16px !important;
  /* padding-right: 20px !important; */
  /* padding: 0.25em 0.25em !important; */
  color: #ffffff !important;
  padding: 2px 7px 12px 8px !important;
  border-radius: 100px !important;
  background-color: #edac3e !important;
  border: 0;
  float: right;
  filter: none;
  border-radius: 0.375rem;
  opacity: 1 !important;
}
.bbbby1 {
  overflow-y: hidden;
  height: 300px;
  padding: 26px !important;
}
@media screen and (min-width: 992px) and (max-width: 1201px) {
  .card_on_file_h5 {
    line-height: 23px !important;
  }
  .rebond_img_setting {
    width: 40% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1401px) {
  .get_reviews_p {
    width: 70% !important;
  }
  .the_consultation_haircolor_in_width {
    width: 60%;
  }
}
.trash_products {
  float: right;
  margin-top: -21px;
  margin-right: 10px;
  font-size: 21px;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 796px !important;
  }
}
@media (min-width: 320px) and (max-width: 992px) {
  .modal-dialog {
    max-width: 100% !important;
    margin-right: auto;
    margin-left: auto;
  }
}

/* pagination */

.pagination {
  height: 36px;
  /* margin: 18px auto; */
  margin: 50px auto !important;
  color: #edac3e;
  width: 320px;
  display: block !important;
}

.pagination ul {
  display: inline-block;
  display: inline;
  /* IE7 inline-block hack */
  zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
  color: #edac3e;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination a:focus {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination .active a {
  color: #ffffff;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #edac3e;
  /* *zoom: 1; */
  zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
  color: #edac3e;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #edac3e;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
}
/* pagination */

.pagination {
  height: 36px;
  margin: 18px 0;
  color: #edac3e;
}

.pagination ul {
  display: inline-block;
  display: inline;
  /* IE7 inline-block hack */
  zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
  color: #edac3e;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination a:focus {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination .active a {
  color: #ffffff;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #edac3e;
  zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
  color: #edac3e;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #edac3e;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: none !important;
}

/* -------AFter Login PAge End -------- */
/* ----Order Buy Page Start --------  */


.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}
/* navbar end */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent !important;
  border: 1px solid #edac3e;
  border-radius: 0px;
}
.right_side_megni_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;
  /* or 150% */
  width: 80%;
  text-transform: uppercase;
  color: #2e2e2e;
}
.star_pettren i {
  color: #edac3e;
  font-size: 15px;
}
.uneek {
  color: #bebebe !important;
}
.order_buy_ul li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 25px;
  /* identical to box height */
  color: #2e2e2e;
}
.sos_orderr_btn {
  background-color: white;
  border: 1px solid rgb(238, 238, 238);
  padding: 10px 0px;
  width: 80%;
  /* padding: 0; */
  font-size: 15px;
  color: rgb(126, 122, 122);
  text-decoration: none;
}
.sos_orderr_btn:hover {
  border: 2px solid #edac3e;
  color: #edac3e;
}
.sos_orderr_btn:focus {
  border: 2px solid #edac3e;
  color: #edac3e;
}

.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  height: 400px;
  margin: 10px auto;
}

.left,
.right {
  flex: 1 1 auto;
  /* border: 1px solid dimgray; */
  max-width: 50%;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left > img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.right {
  position: relative;
  overflow: hidden;
}

.inner {
  position: absolute;
  width: 400%;
  height: 400%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.inner > img {
  width: 100%;
  height: auto;
}
.right {
  display: none;
}
.wrapper:hover .right {
  display: block !important;
  background-color: white;
}
/* re */
.wrapper1 {
  display: flex;
  height: 100vh;
  width: 100%;
  height: 400px;
  margin: 10px auto;
}
.left1,
.right1 {
  flex: 1 1 auto;
  /* border: 1px solid dimgray; */
  max-width: 50%;
}

.left1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left1 > img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.right1 {
  position: relative;
  overflow: hidden;
}

.inner1 {
  position: absolute;
  width: 400%;
  height: 400%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner1 > img {
  width: 100%;
  height: auto;
}
.right1 {
  display: none;
}
.wrapper1:hover .right1 {
  display: block !important;
  background-color: white;
}

.lorem_word_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  color: #2e2e2e;
}
.lorem_word_p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
  /* or 178% */
  color: #2e2e2e;
}
.lorem_ull li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  /* or 178% */
  color: #3b3b3b;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0px !important;
}
.accordion-button:not(.collapsed) {
  color: #edac3e;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #f0f0f0;
  outline: 0;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
  filter: brightness(0.1);
}
.accordion-button {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #4d4d4d;
}

/* card */

.card_buy {
  height: auto;
  /* background-color: red; */
  box-shadow: 1px 1px 5px #9b9a9aad;
}
.card_present {
  /* height: 20px;
    width: 50px; */
  background-color: #edac3e;
  font-size: 12px;
  color: white;
  padding: 5px 10px;
}
.cosdrem_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.star_pettren1 i {
  color: #edac3e;
  font-size: 15px;
}
.star_pettren1 {
  margin-top: -12px;
}
.money_bills_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* or 169% */
  color: #edac3e;
}
.money_bills_p span {
  color: #727272;
}
.give_rating1 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  color: white;
  padding: 10px 15px;
  width: 200px;
  text-decoration: none;
  transition: all 0.5s;
  /* margin: 0px 10px; */
}
.give_rating1:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}
.card_present1 {
  /* height: 20px;
    width: 50px; */
  background-color: #ffffff;
  font-size: 12px;
  color: #edac3e;
  border: 1px solid #edac3e;
  padding: 5px 10px;
}
.card_present1:active {
  background-color: #edac3e;
  color: white;
}
/* card */

.low_btn2 {
  padding: 10px 30px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e;
  /* margin: 0px 20px; */
  transition: all 0.5s;
}
.low_btn2:hover {
  color: #ffffff;
  background-color: #edac3e;
}
.low_btn4 {
  padding: 10px 30px;
  background-color: #edac3e;
  color: rgb(255, 255, 255);
  text-decoration: none;
  border: 1px solid #edac3e;
  /* margin: 0px 20px; */
  transition: all 0.5s;
}
.low_btn4:hover {
  color: #edac3e;
  background-color: white;
}
.zoom {
  zoom: 1.1;
  user-select: none;
}
/* ----Order Buy Page End --------  */

/* -------Payment Method Page Start --- */




.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}

.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}

.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}

.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

ul.dropdown-menu.show {
  width: 217px;
}

.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}

.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}

.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}

.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}

.dropdown-item {
  color: #555555;
}

.dropdown-item:hover {
  color: black;
}

.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.ongoing_section_right h4 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  text-transform: uppercase;
  color: #edac3e;
}

.ongoing_section {
  background-color: #f9f9f9;
  position: relative;
  border: 1px solid #e7e7e7;
}

.select_bar {
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  background-color: #f7f7f7;
  border: none;
  color: #797979 !important;
  outline: none;
}

.input_create {
  background-color: #f7f7f7;
  width: 100%;
  height: 50px;
  padding: 18px;
}

.input_create input {
  width: 90%;
  height: 40px;
  border: none;
  outline: none;
  background-color: transparent;
}

.fa-check {
  text-align: center;
  background-color: #edac3e;
  color: white;
  padding: 5px 6px;
  border-radius: 100px;
  margin-top: 15px;
}

select {
  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* arrows */

select.classic {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

select.round {
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgb(255, 255, 255) 50%
    ),
    linear-gradient(135deg, rgb(255, 255, 255) 50%, transparent 50%),
    radial-gradient(#edac3e 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

select.round:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.proceed_to_pay a {
  background-color: #edac3e;
  padding: 10px 30px;
  border: 1px;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  border: 1px solid #edac3e;
}

.proceed_to_pay a:hover {
  background-color: white;
  color: #edac3e;
  border: 1px solid #edac3e;
}

.proceed_to_pay {
  float: right;
}
.sub_total {
  background-color: #f9f9f9;
}
.sub_total_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 7px;
  /* identical to box height */
  color: #2e2e2e;
}
.sub_total_p span {
  float: right;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  color: #2e2e2e;
}
.sub_total_pp {
  /* position: absolute; */
  left: 65.78%;
  right: 31.46%;
  top: 33.85%;
  bottom: 64.94%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #edac3e;
}
.sub_total_pp span {
  float: right;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  color: #2e2e2e;
  color: #edac3e;
}
.visa_img {
  border-radius: 10px;
  background-color: #edac3e;
  height: 70px;
}
.upi_img {
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 70px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  /* identical to box height */
  color: #2e2e2e;
}
label {
  color: #555555;
}

/* ------Payment Method Page End */

/* ---Find Product Page Start */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.find_something_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 50px;
  /* identical to box height, or 178% */
  text-align: center;
  color: #2e2e2e;
}
.find_something_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  /* or 202% */
  text-align: center;
  color: #242626;
  opacity: 0.7;
}
/* card */

.card_buy {
  height: auto;
  /* background-color: red; */
  box-shadow: 1px 1px 5px #9b9a9aad;
}
.card_present {
  /* height: 20px;
    width: 50px; */
  background-color: #edac3e;
  font-size: 12px;
  color: white;
  padding: 5px 10px;
}
.cosdrem_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2e2e2e;
}
.star_pettren1 i {
  color: #edac3e;
  font-size: 15px;
}
.star_pettren1 {
  margin-top: -12px;
}
.money_bills_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* or 169% */
  color: #edac3e;
}
.money_bills_p span {
  color: #727272;
}
.give_rating1 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  color: white;
  padding: 10px 15px;
  width: 200px;
  text-decoration: none;
  transition: all 0.5s;
  /* margin: 0px 10px; */
}
.give_rating1:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

/* card */

.find_by_category {
  background-color: #f7f7f7;
}
.find_by_category_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 80px;
  /* identical to box height, or 333% */
  text-align: center;
  color: #2e2e2e;
}
.form-select:focus {
  box-shadow: none;
}
/* option:hover{
    background-color: #EDAC3E;
  }
  .option:active{
    background-color: #EDAC3E;
  } */

/* input color change start */

.sb-checkbox {
  margin: 10px;
}
.sb-checkbox {
  display: inline-block;
  font-size: 0;
  width: 100%;
}
.sb-checkbox__input {
  display: none;
}
.sb-checkbox__input:checked + .sb-checkbox__label:after {
  opacity: 1;
}
.sb-checkbox__label {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
}
.sb-checkbox__label:before {
  content: "";
  width: 16px;
  height: 16px;
  border: 2px solid;
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
}
.sb-checkbox__label:after {
  content: "done";
  font-family: "Material Icons";
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  opacity: 0;
  transition: 0.2s opacity;
}
.sb-checkbox__label--green:before {
  border-color: #edac3e;
}
.sb-checkbox__label--green:after {
  background-color: #edac3e;
  color: #fff;
}
.checkbox_lebel {
  margin-top: -5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 500% */
  color: #7d7d7d;
}
.low_btn {
  padding: 7px 30px;
  background-color: #e2e2e2;
  color: rgb(70, 70, 70);
  text-decoration: none;
  margin: 0px 20px;
}
.low_btn:hover {
  color: black;
}
.enter_loction {
  background-color: #efefef;
  text-align: center;
  height: 40px;
  border: none;
  outline: none;
  color: #949494;
}
.low_btn2 {
  padding: 10px 30px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e;
  margin: 0px 20px;
  transition: all 0.5s;
}
.low_btn2:hover {
  color: #ffffff;
  background-color: #edac3e;
}
.low_btn4 {
  padding: 10px 30px;
  background-color: #edac3e;
  color: rgb(255, 255, 255);
  text-decoration: none;
  border: 1px solid #edac3e;
  margin: 0px 20px;
  transition: all 0.5s;
}
.low_btn4:hover {
  color: #edac3e;
  background-color: white;
}

/* input color change end */

.card_present1 {
  background-color: #ffffff;
  font-size: 12px;
  color: #edac3e;
  border: 1px solid #edac3e;
  padding: 5px 10px;
  cursor: pointer;
}
.card_present2 {
  background-color: #edac3e;
  font-size: 12px;
  color: white;
  border: 1px solid #edac3e;
  padding: 5px 10px;
  cursor: pointer;
}
.card_present1:active {
  background-color: #edac3e;
  color: white;
}
.trash_products {
  float: right;
  margin-top: -21px;
  margin-right: 10px;
  font-size: 21px;
  cursor: pointer;
}
/* pagination */

.pagination {
  height: 36px;
  /* margin: 18px auto; */
  margin: 50px auto !important;
  color: #edac3e;
  width: 280px;
  display: block !important;
}

.pagination ul {
  display: inline-block;
  display: inline;
  /* IE7 inline-block hack */
  zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
  color: #edac3e;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination a:focus {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination .active a {
  color: #ffffff;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #edac3e;
  zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
  color: #edac3e;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #edac3e;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
}
/* pagination */

.pagination {
  height: 36px;
  margin: 18px 0;
  color: #edac3e;
}

.pagination ul {
  display: inline-block;
  display: inline;
  /* IE7 inline-block hack */
  zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
  color: #edac3e;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination a:focus {
  background-color: #edac3e;
  color: #ffffff;
}

.pagination .active a {
  color: #ffffff;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #edac3e;
  zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
  color: #edac3e;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #edac3e;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: none !important;
}
.free_contant {
  background: #f7f7f7;
  padding: 75px 0px;
  text-align: center;
  margin-top: 24px;
  /* font-size: 20px; */
}
.clear_btn {
  padding: 2px 10px;
  background-color: #edac3e;
  color: white;
  text-decoration: none;
  position: relative;
  top: 25px;
  border: 1px solid #edac3e;
  transition: all 0.5s;
  cursor: pointer;
}
.clear_btn:hover {
  background-color: white;
  color: #edac3e;
}
.inputtype_1 {
  width: 70px;
  border: 2px solid #edac3e;
  padding: 0px 5px;
  outline: #edac3e;
}
.inputtype_2 {
  width: 70px;
  float: right;
  border: 2px solid #edac3e;
  padding: 0px 5px;
  outline: #edac3e;
}
/* Find Product PAge End */

/* ---------- Service Page Start =---------*/

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: 250px;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 10px 25px;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252);
  color: #edac3e;
  border: 1px solid #edac3e;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e !important;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

ul.dropdown-menu.show {
  width: 217px;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.natural_hairr {
  background-color: rgb(255, 255, 255);
  height: auto;
  box-shadow: 0px 38px 75px rgba(0, 0, 0, 0.05);
  position: relative;
}
.single_arrow_rotate {
  transform: rotate(90deg);
  margin: 10px;
}
.natural_hair_search {
  height: 60px;
  width: 100%;
  border: 1px solid black;
}
.secrh_natural {
  height: 40px;
  margin-top: -10px;
  outline: none;
  border: none;
  font-size: 15px;
  width: 112%;
}
.newServiceDate {
  /* border: 2px solid #0097e6; */
  border: none;
  background: none;
  display: block;
  margin: 5px 12px auto;
  padding: 0px 0px;
  width: 160px;
  outline: none;
  color: rgb(0, 0, 0);
  /* border-radius: 25px; */
  /* text-align: center; */
  transition: 250ms width ease, 250ms border-color ease;
}
.single_arrow_rotate1 {
  transform: rotate(90deg);
  margin: 0px;
  margin-left: 11px;
}
.natural_hair_search_btn {
  background-color: black;
  color: white;
  /* padding: 10px 100px; */
  padding: 16px 0;
  text-align: center;
  text-decoration: none;
}
.natural_hair_search_btn {
  color: white !important;
}
.cut_in_houston {
  background-color: #ffffff;
  margin-top: -49px;
  /* position: relative; */
  /* z-index: -1; */
  /* box-shadow: 1px 5px 10px #020250; */
  /* position: relative; */
  z-index: -1;
  height: auto;
}
.cut_in_houston_in_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 30px;
  color: #2e2e2e;
  text-transform: capitalize;
}
.hair_and_makeup_card {
  height: auto;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.144);
  margin-top: 10px;
  border: 1px solid #dbdbdb;
}
.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  text-align: left;
  font-size: 21px;
  line-height: 30px;
}

.star_pettren i {
  color: #edac3e;
  font-size: 13px;
}
.uneek {
  color: #bebebe !important;
}
.star_pettren_span {
  color: #666666;
  font-size: 14px;
}
.woman_cut_left {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_right {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #666666;
}


@media screen and (min-width: 320px) and (max-width: 470px) {
  #br_hide {
    display: block !important;
    /* margin-top: 20px !important; */
  }
  .view_more_btn1 {
    margin-top: 40px !important;
  }
}
/* footer css */
/* footer{
        position: absolute;
        width: 100%;
        top: 100px;
    } */
.footer_up {
  background-color: #fbf5ef;
  height: auto;
}
.free_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: rgb(44, 44, 44);
}
.asked_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}
.footer_nd_list {
  float: right;
}
/* footer css end */
.main_element {
  height: auto;
}

/* message modal start */

.modal-content1 {
  border-radius: 0px;
}
/* .modal-dialog {
        height: 80% !important;
        padding-top:0%;
      } */

.modal-body {
  height: 80%;
  overflow: auto;
}

.modal-footer1 {
  display: block;
  border: none;
}
.send_btn1 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.send_btn1:hover {
  background-color: white !important;
  color: #edac3e !important;
}
.cancel_btn1 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.cancel_btn1:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.modal_input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-top: 20px !important;
  box-shadow: 0px 0px 5px lightgrey;
  color: #747474;
}
.the_consultation_haircolor_h5_modal {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 27px !important;
}
.the_consultation_haircolor_p12 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 10px !important;
  color: #edac3e !important;
}
.the_consultation_haircolor_p23 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 25px !important;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
  margin-top: -10px !important;
}
.modal_height2 {
  height: 70% !important;
  overflow-x: scroll;
}
.send_btn12 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}

.single_arrow_rotate {
  transform: rotate(90deg) !important;
  margin: 10px;
}
.add_services_btn {
  padding: 10px 30px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.add_services_btn:hover {
  background-color: white;
  color: #edac3e;
}

/* calender */
#calendar {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-family: "Lato", sans-serif;
}
#calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
}
#calendar_content,
#calendar_weekdays,
#calendar_header {
  position: relative;
  width: 320px;
  overflow: hidden;
  float: left;
  z-index: 10;
}
#calendar_weekdays div,
#calendar_content div {
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  color: #787878;
}
#calendar_content {
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}
#calendar_content div {
  float: left;
}
#calendar_content div:hover {
  background-color: #f8f8f8;
}
#calendar_content div.blank {
  background-color: #e8e8e8;
}
#calendar_header,
#calendar_content div.today {
  zoom: 1;
  filter: alpha(opacity=70);
  /* opacity: 0.7; */
}
#calendar_content div.today {
  color: #ffffff;
}
#calendar_header {
  width: 100%;
  height: 37px !important;
  text-align: center;
  background-color: #edac3e !important;
  font-size: 11px;
  padding: 9px 0;
  -webkit-border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  border-radius: 0px;
}
#calendar_header h1 {
  font-size: 1.5em;
  color: #ffffff;
  /* float:left; */
  /* width:70%; */
}
i[class^="icon-chevron"] {
  color: #ffffff;
  float: left;
  width: 15%;
  border-radius: 50%;
}

#calendar_content div.blank {
  background-color: #ffffff;
}

.nofty_btn {
  background-color: #edac3e !important;
  color: white !important;
  padding: 5px 7px !important;
  /* margin-top: -41px !important;
        position: absolute; */
}

@media screen and (min-width: 320px) and (max-width: 405px) {
  .nofty_btn {
    position: static;
  }
}

/* calender */

.desable_btn1 {
  padding: 7px 10px;
  color: #afafaf;
  border: 1px solid #edac3e;
  font-size: 11px;
  background-color: white;
}
.desable_btn1:hover {
  background-color: #edac3e;
  color: white;
}
.desable_btn1:active {
  background-color: #edac3e !important;
  color: white;
}
.desable_btn1:focus {
  background-color: #edac3e !important;
  color: white;
}
.my_offers_btn {
  color: #ffffff !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

input {
  /* width: 56px;
  height: 39px; */
  /* font-size: 12px; */
  line-height: 12px;
  /* padding: 5px; */
  border: none;
}
input.ccFormatMonitor {
  /* width: 240px; */
}

input.ccFormatMonitor {
  width: 70%;
  outline: none;
  border: none;
}
.input_out_line {
  border: 1px solid black;
}

@media screen and (min-width: 320px) and (max-width: 502px) {
  input.ccFormatMonitor {
    width: 80%;
  }
}
.my_offers_btn2 {
  color: #edac3e !important;
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}
.modal-header12 {
  background-color: #edac3e;
  border-radius: 0px;
  text-align: center;
  display: block !important;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.update_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */

  text-transform: capitalize;
}
.checkbox_z {
  width: 17px;
  position: relative;
  top: 9px;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type="checkbox"]:after {
  box-shadow: none !important;
}

/* message modal end */

ul.list-group {
  width: 298px;
  /* position: absolute; */
  margin-left: -42px;
  margin-top: 9px;
  border-radius: 0px;
  /* right: 10px; */
}
input {
  /* width: 56px;
  height: 39px; */
  /* font-size: 12px; */
  line-height: 12px;
  /* padding: 5px; */
  border: none;
  /* margin-top: -4px; */
}
.free_contant {
  background: #f7f7f7;
  padding: 75px 0px;
  text-align: center;
  margin-top: 24px;
  /* font-size: 20px; */
}
.card_height {
  height: 600px;
  overflow-y: scroll;
}
/* --- Service Page End ----------*/

/* ---------Service Details Page Start ----------- */

.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}
.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}
.Profile_sign_btn {
  padding: 8px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0px !important;
}
.Profile_sign_btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}

/* navbar end */

.hair_and_makeup_card_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 30px;
}

.star_pettren i {
  color: #edac3e;
  font-size: 13px;
}
.uneek {
  color: #bebebe !important;
}
.star_pettren_span {
  color: #666666;
  font-size: 20px;
}
.woman_cut_left {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_right {
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.144);
  width: 100%;
}
.woman_cut_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  padding-top: 5px;
  line-height: 8px;
  color: #666666;
  margin-bottom: 20px;
}

.view_more_btn1 {
  border: 0.954545px solid #edac3e;
  padding: 15px 40px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn1:hover {
  background-color: #ffffff;
  color: #edac3e;
}
@media screen and (min-width: 320px) and (max-width: 470px) {
  #br_hide {
    display: block !important;
    /* margin-top: 20px !important; */
  }
  .view_more_btn1 {
    margin-top: 40px !important;
  }
}
.rebond_img img {
  height: 145px;
}
@media screen and (min-width: 320px) and (max-width: 776px) {
  .rebond_img img {
    height: auto !important;
  }
}
.rebond_view {
  height: 145px;
  width: 100%;
  /* background-image: url(../../assets/images/rebon3.png); */
  /* filter: brightness(0.4); */
  background-size: cover;
  /* background-blend-mode: darken; */
  background-color: rgba(0, 0, 0, 0.5);
  fill-opacity: 0.5s;
  /* background-color: red; */
  /* filter: brightness(0.4); */
}
.rebond_view:hover {
  cursor: pointer;
}
.rebond_view h5 {
  color: white;
  text-align: center;
  padding-top: 60px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.1818px;
  line-height: 21px;
  text-transform: capitalize;
}
#view_all_rebond {
  display: none;
}
.limited_availability {
  background-color: #fefbf8;
}
.fa-stopwatch {
  color: #edac3e;
  font-size: 35px;
}
.limited_availability h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
}
.limited_availability p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #666666;
}

/* nav and tabs start */
.nav-pills.serviceDetails .nav-link.active,
.nav-pills .show > .nav-link {
  color: #edac3e !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 2px solid #edac3e !important;
  border-radius: 0 !important;
}

.serviceDetails.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size) !important;
  font-weight: var(--bs-nav-link-font-weight);
  color: #afafaf !important;
  font-size: 19px !important;
  margin: 0px 8px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  border: none !important;
}
.serviceDetails {
  padding: 10px 10px !important;
}

.shakita_byrd21 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
}
.see_time_btn_serviceDetails {
  padding: 10px 46px !important;
  background-color: #edac3e;
  color: white !important;
  text-decoration: none !important;
  border: 1px solid #edac3e;
  cursor: pointer;
}
/* service navs start */
.the_consultation {
  background-color: #f9f9f9;
}
.the_consultation_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.the_consultation_haircolor {
  /* background-color: #FFFFFF; */
}
.the_consultation_haircolor_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.the_consultation_haircolor_p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 13px;
  padding-top: 5px;
  text-transform: capitalize;
  color: #edac3e;
}
.the_consultation_haircolor_pp {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  /* or 181% */
  text-transform: capitalize;
  color: #5a5a5a;
}
.see_time_btn {
  padding: 10px 50px;
  background-color: #edac3e;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e;
}
.see_time_btn:hover {
  background-color: #ffffff;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: 0.5s;
}
.more_info_btnn {
  /* padding: 10px 50px; */
  background-color: #ffffff;
  border: 1px solid #edac3e;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.setbutton {
  padding: 10px 50px;
}
.more_info_btnn:hover {
  background-color: #edac3e;
  color: white;
  text-decoration: none;
}
.shakita_byrd {
  height: 489px;
  /* background-color: red; */
}
.shakita_byrd2 {
  background-color: #f9f9f9;
}
.shakita_byrd2 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
}
.shakita_byrd2 p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #5a5a5a;
}
.message_btn {
  padding: 10px 50px !important;
  background-color: #edac3e !important;
  text-decoration: none;
  color: white;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
}
.message_btn:hover {
  background-color: white !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}
.business_hours {
  background-color: #f9f9f9;
}
.time_right_side_sett {
  float: right;
  color: #5a5a5a;
  font-size: 15px;
}
.business_hours h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.business_hours p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 5px;
  color: #272727;
}
.shakita_cancellation h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  text-align: center;
}
.shakita_cancellation p {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #5a5a5a;
}
.shakita_cancellation h6 {
  text-align: center;
}
.facebook_i {
  padding: 13px 10px;
  background-color: #edac3e;
  border-radius: 100px;
  height: 44px;
  color: white;
  width: 44px;
  transition: all 0.5s;
}
.icons_list li {
  display: inline-block;
  padding: 0px 4px;
}

.icons_list {
  margin-left: -37px;
}
.facebook_i:hover {
  color: #edac3e;
  border: 1px solid #edac3e;
  transform: rotate(360deg);
  background-color: white;
}

/* service navs end */

/* review navs start */
.star_icon_yellow {
  color: #edac3e;
}
.star_icon_yellow1 {
  font-size: 18px;
  color: #edac3e;
  margin-top: -10px;
}
.progress-bar {
  background-color: #edac3e !important;
  font-weight: 800;
  padding: 9px 0px;
}
.progress {
  width: 76%;
}
#view_all_rebond1 {
  display: none;
}
.star_icon_yellow2 {
  font-size: 14px;
  color: #edac3e;
  padding-top: -10px;
}
.the_consultation_haircolor_p1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
}
.the_consultation_haircolor_p2 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
}
.the_consultation_h51 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}
.the_consultation_p1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  /* identical to box height, or 171% */
  color: #555555;
}
.the_consultation_pera {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  /* or 171% */
  color: #555555;
}
/* review navs end */

/* products navs start */

.the_consultation_ul li {
  list-style: none;
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 31px;
  color: #555555;
}
.the_consultation_ul {
  margin-left: -30px;
}
.bottom_btn {
  position: absolute;
  bottom: 30px;
}
.the_consultation_p2 {
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 31px;
  /* or 171% */
  color: #555555;
}

/* products navs end */
.nav-pills .nav-link:hover {
  color: #edac3e;
}

/* message modal start */

.modal-content1 {
  border-radius: 0px;
}
/* .modal-dialog {
        text-align: center !important;
        width: 20% !important;
        height: 20% !important;
        padding-top:0%;
      } */

.modal-dialog {
  text-align: center !important;
  width: 100% !important;
  padding-top: 0%;
}

.modal-content {
  height: 79% !important;
  overflow: visible;
}
.modal-header1 {
  background-color: #edac3e;
  border-radius: 0px;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0px -18px;
  padding: 10px 27px;
}

.modal-footer1 {
  display: block;
  border: none;
}
.send_btn1 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.send_btn1:hover {
  background-color: white !important;
  color: #edac3e !important;
}
.cancel_btn1 {
  color: #edac3e !important;
  background-color: white !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}
.cancel_btn1:hover {
  color: white !important;
  background-color: #edac3e !important;
}
.modal_input {
  width: 90%;
  outline: none;
  border: none;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-top: 20px !important;
  /* box-shadow: 0px 0px 5px lightgrey; */
  color: #747474;
}
.modal_input_new {
  height: 48px;
}
.the_consultation_haircolor_h5_modal {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 27px !important;
}
.the_consultation_haircolor_p12 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 10px !important;
  color: #edac3e !important;
}
.the_consultation_haircolor_p23 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 25px !important;
  /* identical to box height, or 181% */
  text-transform: capitalize;
  color: #edac3e;
  margin-top: -10px !important;
}
.modal_height2 {
  height: 70% !important;
  overflow-x: scroll;
}
.send_btn12 {
  color: white !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  padding: 7px 50px !important;
  border-radius: 0px;
  transition: all 0.5s;
}

.single_arrow_rotate {
  transform: rotate(90deg) !important;
  margin: 10px;
}
.add_services_btn {
  padding: 10px 30px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.add_services_btn:hover {
  background-color: white;
  color: #edac3e;
}

/* calender */
#calendar {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-family: "Lato", sans-serif;
}
#calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
}
#calendar_content,
#calendar_weekdays,
#calendar_header {
  position: relative;
  width: 320px;
  overflow: hidden;
  float: left;
  z-index: 10;
}
#calendar_weekdays div,
#calendar_content div {
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  color: #787878;
}
#calendar_content {
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}
#calendar_content div {
  float: left;
}
#calendar_content div:hover {
  background-color: #f8f8f8;
}
#calendar_content div.blank {
  background-color: #e8e8e8;
}
#calendar_header,
#calendar_content div.today {
  zoom: 1;
  filter: alpha(opacity=70);
  /* opacity: 0.7; */
}
#calendar_content div.today {
  color: #ffffff;
}
#calendar_header {
  width: 100%;
  height: 37px !important;
  text-align: center;
  background-color: #edac3e !important;
  font-size: 11px;
  padding: 9px 0;
  -webkit-border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  border-radius: 0px;
}
#calendar_header h1 {
  font-size: 1.5em;
  color: #ffffff;
  /* float:left; */
  /* width:70%; */
}
i[class^="icon-chevron"] {
  color: #ffffff;
  float: left;
  width: 15%;
  border-radius: 50%;
}

#calendar_content div.blank {
  background-color: #ffffff;
}

.nofty_btn {
  background-color: #edac3e !important;
  color: white !important;
  padding: 5px 7px !important;
  /* margin-top: -41px !important;
        position: absolute; */
}
.booked_p {
  color: #555555;
  font-size: 10px;
  margin-top: 2px;
}
.rejected_p {
  color: red;
  font-size: 8px;
}

@media screen and (min-width: 320px) and (max-width: 405px) {
  .nofty_btn {
    position: static;
  }
}

/* calender */

.my_offers_btn {
  color: #ffffff !important;
  background-color: #edac3e !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

/* input {
        width: 56px;
        height: 30px;
        font-size: 12px;
        line-height: 12px;
        padding: 5px;
        border: none;
      } */
input.ccFormatMonitor {
  width: 240px;
}

input.ccFormatMonitor {
  width: 94%;
  outline: none;
  border: none;
}
.input_out_line {
  border: 1px solid black;
}

@media screen and (min-width: 320px) and (max-width: 502px) {
  input.ccFormatMonitor {
    width: 44%;
  }
}
.my_offers_btn2 {
  color: #edac3e !important;
  background-color: #ffffff !important;
  border: 1px solid #edac3e !important;
  /* padding: 7px 50px !important; */
  border-radius: 0px;
  width: 90%;
  transition: all 0.5s;
  text-align: left;
}

.nofty_btn21 {
  background-color: #edac3e !important;
  color: white !important;
  padding: 8px 21px !important;
  margin-top: -41px !important;
  /* position: absolute; */
}
.modal-header12 {
  background-color: #edac3e;
  border-radius: 0px;
  text-align: center;
  display: block !important;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.update_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */

  text-transform: capitalize;
}
.checkbox_z {
  width: 17px;
  position: relative;
  top: 9px;
}
input[type="checkbox"]:checked {
  background-color: #a77e2d !important;
  color: #ffffff !important;
}
input[type="checkbox"]:after {
  box-shadow: none !important;
}

/* message modal end */

/* nav and tabs end */

ul.dropdown-menu.show {
  width: 217px;
  display: grid;
}
.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}
.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}
.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}
.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}
.dropdown-item {
  color: #555555;
}
.dropdown-item:hover {
  color: black;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

.my-model {
  width: 40% !important; /* Occupy the 90% of the screen width */
  max-width: 100% !important;
}
@media screen and (min-width: 992px) and (max-width: 1220px) {
  .my-model {
    width: 60% !important; /* Occupy the 90% of the screen width */
    max-width: 60% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .my-model {
    width: 100% !important; /* Occupy the 90% of the screen width */
    max-width: 100% !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  .my-model {
    width: 70% !important; /* Occupy the 90% of the screen width */
    max-width: 100% !important;
  }
}

/* footer css */
/* footer{
        position: absolute;
        width: 100%;
        top: 100px;
    } */
.footer_up {
  background-color: #fbf5ef;
  height: auto;
}
.free_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: rgb(44, 44, 44);
}
.asked_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}
.footer_nd_list {
  float: right;
}
/* footer css end */
.main_element {
  height: auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.modal-dialog {
  /* height: 30% !important; */
  /* padding-top:10%; */
}

.modal-body {
  height: 40%;
  overflow: auto;
}
.view_more_btn11 {
  border: 0.954545px solid #edac3e;
  padding: 12px 40px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
  border-radius: 0px !important;
}
.view_more_btn11:hover {
  background-color: #ffffff;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}
#hide_h5 {
  position: relative;
  top: -80px;
  color: white;
  height: 0px;
  left: 26px;
  line-height: 0px;
}
.tingrii_a1 {
  height: 170px;
  width: 170px;
  border-radius: 100px;
}

.tingrii_a1_message {
  height: 100px;
  width: 100px;
  border-radius: 100px;
}
.the_consultation_haircolor_color_setup {
  background-color: white;
  padding: 20px 0px;
}

/* --------Service Details PAge End ------------ */

/* ---------Shipping Page Start -------------- */




.main_splash2 {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: auto;
  position: relative;
}

.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}

.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}

.get_doller:hover {
  background-color: #edac3e;
  border: 1px solid #edac3e;
  color: white;
}

ul.dropdown-menu.show {
  width: 217px;
}

.book_now_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 7px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  transition: all 0.5s;
}

.book_now_btn12:hover {
  background-color: #edac3e;
  color: white;
}

.view_more_btn12 {
  border: 0.954545px solid #edac3e;
  padding: 7px 47px;
  background-color: #edac3e;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}

.view_more_btn12:hover {
  background-color: #ffffff;
  color: #edac3e;
}

.dropdown-item {
  color: #555555;
}

.dropdown-item:hover {
  color: black;
}

.my_account_h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 80px;
  color: #2e2e2e;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #edac3e;
}

/* navbar end */

.ongoing_section_right h4 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  text-transform: uppercase;
  color: #edac3e;
}

.ongoing_section {
  background-color: #f9f9f9;
  position: relative;
}

.select_bar {
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  background-color: #f7f7f7;
  border: none;
  outline: none;
  color: #797979;
}

.input_create {
  background-color: #f7f7f7;
  width: 100%;
  height: 50px;
}

.input_create input {
  width: 90%;
  height: 40px;
  border: none;
  outline: none;
  background-color: transparent;
}

.fa-check {
  text-align: center;
  background-color: #edac3e;
  color: white;
  padding: 5px 6px;
  border-radius: 100px;
  margin-top: 15px;
}

select {
  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* arrows */

select.classic {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

select.round {
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgb(255, 255, 255) 50%
    ),
    linear-gradient(135deg, rgb(255, 255, 255) 50%, transparent 50%),
    radial-gradient(#edac3e 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

select.round:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.proceed_to_pay button {
  background-color: #edac3e;
  padding: 10px 30px;
  border: 1px;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  border: 1px solid #edac3e;
}

.proceed_to_pay button:hover {
  background-color: white;
  color: #edac3e;
  border: 1px solid #edac3e;
}

.proceed_to_pay {
  float: right;
}
/* ---------Shipping Page End ------------ */

/* --------Otp Page Start--------- */
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  /* height: 1200px; */
}
.otp-form {
  /* vertical-align: middle !important; */
  margin-top: 15%;
}
.title {
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.title h3 {
  font-weight: bold;
  font-size: 32px;
}
.title p {
  font-size: 15px;
  color: #ffb413;
}
.title p.msg {
  color: initial;
  text-align: center;
  font-weight: bold;
}
.otp-input-fields {
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 8px 0px #020250 44;
  max-width: 400px;
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 13px 0px 19px 0px;
}
.otp-input-fields input {
  height: 62px;
  width: 62px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ffd271;
  text-align: center;
  outline: none;
  font-size: 16px;
  /* Firefox */
}
.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-input-fields input[type="number"] {
  -moz-appearance: textfield;
}
.otp-input-fields input:focus {
  border-width: 2px;
  border-color: #ffb413;
  font-size: 20px;
}
.result {
  max-width: 400px;
  margin: auto;
  padding: 24px;
  text-align: center;
}
.result p {
  font-size: 24px;
  font-family: "Antonio", sans-serif;
  opacity: 1;
  transition: color 0.5s ease;
}
.result p._ok {
  color: green;
}
.result p._notok {
  color: red;
  border-radius: 3px;
}
.submit_number {
  padding: 7px 45px;
  background-color: #ffb413;
  border: 1px solid #ffb413;
  color: white;
  transition: 0.5s;
}
.submit_number:hover {
  background-color: white;
  border: 1px solid #ffb413;
  color: #ffb413;
}
/* ---------OTP page End ------------- */

/* --------Home Page Start ---------- */

.main_splash {
  /* min-width: 100%; */
  min-height: fit-content;
  background-color: #fbf5ef;
  height: 640px;
  /* position: relative; */
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 0px 10px;
  font-weight: 500;
  font-size: 15px;
}
/* .login_btn{
    padding: 10px 25px;
    background-color: white;
    border: 1px solid black;
    color: black;
    transition: all 0.5s;
    text-decoration: none;
}
.login_btn:hover{
    background-color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 255, 255);
    color: white;
} */
.sign_btn {
  padding: 10px 25px;
  background-color: black;
  color: white;
  border: 1px solid black;
  transition: all 0.5s;
  text-decoration: none;
}
.sign_btn:hover {
  background-color: rgb(252, 252, 252);
  color: rgb(0, 0, 0);
  border: 1px solid black;
}
@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: block;
    padding: 10px 10px;
  }
  .navbar_list {
    margin-left: -40px;
  }
  .first_section_discover_left h1 {
    font-family: "Rufina";
    font-style: normal;
    font-weight: 100;
    font-size: 52px;
    line-height: 85px;
    text-transform: capitalize;
  }
  .first_section_discover_right_img1 {
    top: 50px !important;
    right: 40px !important;
    width: 80%;
  }
  .first_section_discover_right_img2 {
    top: 90px !important;
    width: 80%;
    right: 0px !important;
  }
  .first_section_discover_right_img2:hover {
    right: 40px !important;
    top: 50px !important;
  }
  .second_sectionn {
    margin-top: 664px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .second_sectionn {
    margin-top: 554px !important;
  }
  .first_section_discover_left h1 {
    font-size: 52px;
  }
  .all_set_container {
    width: 100% !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 1400px) {
  .main_splash {
    height: auto;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .second_sectionn {
    margin-top: 144px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 497px) {
  .share_now_btnn {
    position: relative;
    left: 32px !important;
    top: 151px !important;
  }
  .style_now img {
    width: 100%;
  }
  .products_name_span_float_btn {
    padding: 16px 2px !important;
  }
  .shampoo_img_set {
    margin-bottom: 88px;
    width: 100px;
  }
}

/* resposive navbar start */
@media screen and (min-width: 0px) and (max-width: 992px) {
  .navbar_list li {
    display: inline-block;
    padding: 10px 18px;
  }
}
/* resposive navbar end */
.first_section_discover {
  height: auto;
  background-color: transparent;
  /* position: absolute; */
}
.first_section_discover_left h1 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 100;
  font-size: 59px;
  line-height: 85px;
  text-transform: capitalize;
}

/* .input_search_one input{
    width: 56px;
    height: 39px;
    font-size: 15px;
    line-height: 12px;
    padding: 3px 10px !important;
    border: none;
} */
.first_section_discover_right {
  position: relative;
}
.first_section_discover_right_img1 {
  position: absolute;
  right: 0px;
}
.first_section_discover_right_img2 {
  position: absolute;
  right: -44px;
  top: 44px;
  transition: all 0.5s;
}
.first_section_discover_right_img2:hover {
  right: 0px;
  top: 0px;
}
.secrch_btn {
  padding: 15px 50px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all 0.5s;
  border: 1px solid rgb(255, 255, 255) !important;
}
.secrch_btn:hover {
  color: black;
  background-color: white;
  border: 1px solid black !important;
}
.secrch_btn1.Home {
  padding: 15px 50px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all 0.5s;
  border: 1px solid rgb(255, 255, 255) !important;
}
.secrch_btn1.Home:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid black !important;
}
.contact_us_list li {
  display: inline-block;
}
.contact_us_list {
  margin-left: -25px;
}
.contact_us_list li:nth-child(2) {
  padding-left: 25px;
}
.contact_us_list li:nth-child(3) {
  padding-left: 25px;
}
.second_sectionn {
  margin-top: 360px;
}
.second_sectionn_h4 {
  text-align: center;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 56px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;
}

.find_top {
  position: relative;
  height: 450px;
  /* transition: all 0.5s !important; */
}
.find_top_bottom_img {
  position: absolute;
  width: 76%;
  height: 350px;
  left: 84px;
  /* transition: all 0.5s !important; */
  top: 29px;
}
.find_top_top_img {
  position: relative;
  width: 80%;
  height: 350px;
  transition: all 0.5s !important;
  object-fit: cover;
}
.find_top h5 {
  text-align: center;
  padding-top: 35px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.find_top p {
  text-align: center;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: rgb(102, 102, 102);
}
.find_top:hover .find_top_bottom_img {
  /* left: 40px;
    top: 30px; */
  transition: all 0.5s !important;
  background-color: #fbf5ef;
}
.browse_products h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 56px;
}
.browse_products p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.886px;
  line-height: 30px;
  color: rgb(199, 199, 199);
}
.shop_now_btn {
  border: 1px solid rgb(173, 173, 173);
  padding: 10px 60px;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}
.shop_now_btn:hover {
  border: 1px solid rgb(173, 173, 173);
  padding: 10px 60px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  background-color: white;
}
.products_name {
  height: auto;
  padding-bottom: -10px;
  background-color: white;
}
.products_name p {
  padding: 0px 10px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 9px;
  color: rgb(77, 77, 77);
  /* text-align: center; */
  text-transform: capitalize;
}
.products_name h5 {
  padding: 0px 10px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  /* identical to box height */
  /* text-align: center; */
  text-transform: capitalize;
}
.products_name_span {
  padding: 0px 10px;
  color: #ac977e;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
}
.products_name_span_float {
  float: right;
  /* margin-right: 10px; */
  display: flex;
  margin-top: -10px;
}
.products_name_span_float_ione {
  background-color: #ededed;
  font-size: 18px;
  padding: 15px;
}
.products_name_span_float_itwo {
  background-color: #ac977e;
  font-size: 19px;
  padding: 14px;
}
.products_name_span_float_btn {
  font-size: 15px;
  background-color: #ac977e;
  padding: 15px 42px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.products_name_span_float {
  /* float: right; */
  /* margin-right: 10px; */
  display: flex;
  margin-top: -10px;
}
.view_more_show {
  float: right;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;
}
.view_more_show a {
  color: white;
  text-decoration: none;
}
#show_proucts {
  display: none;
}
.view_more_show:hover {
  cursor: pointer;
  color: #ac977e;
}
.meet_stylesheet {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
}
/* owl slider start */
.item_slider {
  /* background: #ff3f4d; */
  /* height: 400px; */
}
.owl-carousel.owl-loaded {
  display: block;
  /* width: 830px; */
  margin: auto;
}

.item_slider1 {
  background-image: url("../src/app/assets/images/slider_img.png");
  background-size: 100% 100%;
  opacity: 0.8;
  background-color: #a3a3a3;
  height: 400px;
}
.play_btn_icon {
  width: 70px !important;
  padding-top: 180px;
}
.item_slider2 {
  background-image: url(../src/app/assets/images/slider_img.png);
  background-size: 100% 100%;
  opacity: 0.8;
  background-color: #a3a3a3;
  height: 400px;
}
.item_slider22 {
  background-image: url(../src/app/assets/images/slider_img.png);
  background-size: 100% 100%;
  opacity: 0.8;
  background-color: #a3a3a3;
  height: 400px;
}
.kamara_h5 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
}
.kamara_p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8d8d8d;
  text-align: center;
  text-transform: capitalize;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: none !important;
}
.owl-nav button span {
  padding: 21px;
}
.owl-nav button span:hover {
  /* background-color: #AC977E; */
  color: #ac977e;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background-color: white !important;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot:nth-child(1) {
  position: relative;
  /* left: -100px !important; */
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  position: relatiave;
  /* left: 100px !important; */
}

/* owl slider end */

.style_now {
  height: 250px;
  background-color: black;
  /* background-image: url(../assets/images/leza.png); */
  background-position: right;
  background-size: 303px 250px;
  background-repeat: no-repeat;
  position: relative;
}
.style_now img {
  position: absolute;
  width: 100%;
  margin: 8px 0px !important;
}
.better_width_frinds {
  background-color: #fbf5ef;
  height: 250px;
  background-image: url(../src/app/assets/images/two-young_beutifull.png);
  background-position: right;
  background-size: 303px 250px;
  background-repeat: no-repeat;
}
.share_now_btn {
  padding: 14px 50px;
  background-color: black;
  color: white;
  text-decoration: none;
}
.better_width_frinds h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}
.better_width_frinds p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8d8d8d;
}
.share_now_btnn {
  padding: 14px 53px;
  background-color: white;
  color: black;
  width: 1;
  text-decoration: none;
  position: relative;
  left: 40px;
  top: 181px;
}

.item_2 {
  height: auto;
  /* background-color: red; */
}
img.mx-auto.d-block.owl_theme_img {
  width: 158px;
}

/* review owl slider start */

.our_client_review {
  height: auto;
  background-color: #fbf5ef;
}
.item_slider_2 {
  height: 500px;
  background-color: white;
}
.owl_slider_22 {
  width: 60%;
}
.profile_review_img {
  width: 100px !important;
}
.item_slider_2 h5 {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 56px;
  /* identical to box height */
/* overflow: scroll; */
  text-align: center;
  text-transform: capitalize;
}
.fa-star {
  color: #ecb240;
}
.star_icon li {
  list-style: none;
  display: inline-block;
  padding: 0px 2px;
}
.star_icon {
  margin-left: -40px;
  margin-top: -15px;
}
.star_date {
  font-size: 12px;
  color: #8d8d8d;
}
.slider_lorem_p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #6c6c6c;
  text-align: center;
}
/* review owl slider end */

/* footer css */
.footer_up {
  background-color: #fbf5ef;
  height: auto;
}
.free_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: rgb(44, 44, 44);
}
.asked_returns {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(136, 136, 136);
}

.footer_lorem {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 19.886px;
  line-height: 33px;
  color: #b3b3b3;
}
.about_h5 {
  color: rgb(212, 212, 212);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 53px;
}
.about_us_ul li {
  color: rgb(211, 211, 211);
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  list-style: none;
}
.about_us_ul {
  margin-left: -8px;
}
.about_ft {
  margin-top: 100px;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .about_ft {
    margin-top: 16px;
  }
}
.copy_right {
  color: rgb(190, 190, 190);
  font-size: 15px;
}
.reduis_angle {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}
.reduis_angle .fa-facebook-f {
  color: white;
  padding: 16px 19px;
}
.reduis_angle .fa-twitter {
  color: white;
  padding: 16px 18px;
}
.reduis_angle .fa-instagram {
  color: white;
  padding: 16px 17px;
}
.reduis_angle .fa-pinterest {
  color: white;
  padding: 16px 17px;
}
.footer_nd_list li {
  display: inline-block;
  padding: 0px 15px;
}
.footer_nd_list {
  float: right;
}
/* footer css end */

.get_doller {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
  transition: all 0.5s;
  text-decoration: none;
}
.navbar_list li {
  display: inline-block;
  list-style: none;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}

.imgitem {
  display: block;
  width: 100%;
  height: 163px !important;
  border-radius: 10px;
  /* margin: 0px 24px; */
}
img.profile_review_img.mx-auto.d-block {
  height: 100px;
  width: 100px;
  border-radius: 100px;
}
@media screen and (min-width: 992px) and (max-width: 1401px) {
  .find_top_bottom_img {
    position: absolute;
    width: 61%;
    height: 185px;
    left: 84px;
    /* transition: all 0.5s !important; */
    top: 29px;
  }
  .find_top_top_img {
    position: relative;
    width: 80%;
    height: 190px;
    transition: all 0.5s !important;
  }
  .find_top{
    height: auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1401px) {
  .input_search {
    height: 44px;
    width: 204px;
    background-color: white;
    border: 1px solid rgb(87, 87, 87);
    float: left !important;
  }
  .second_sectionn {
    margin-top: 200px !important;
  }
}
.css_image1 {
  background-position: center !important;
}
.css_image12 {
  margin: -11px -19px 13px -9px !important;
}
.products_name_span_float_set_jt {
  margin-bottom: 50px;
}
.products_name_span_float_set_jt1 {
  margin-top: -6px !important;
}
@media screen and (min-width: 520px) and (max-width: 992px) {
  .style_now {
    height: 272px;
  }
}
.view_more_show_new {
  padding: 7px 27px;
  color: rgb(0 0 0) !important;
  /* background-color: rgb(0, 0, 0); */
  text-decoration: none;
  margin-top: 26px;
  transition: all 0.5s;
  /* border: 1px solid rgb(255, 255, 255) !important; */
}
.postion_set {
  position: absolute;
  z-index: 999;
}
ul.list-group {
  position: absolute;
  top: -48px;
  border-radius: 0px;
  left: 5px;
  width: 220px;
  z-index: 999 !important;
}
.list-group-item:hover {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: #ebebeb;
}

.imgitem {
  display: block;
  width: 95% !important;
}
/* -----Home Page End ------------- */

.modal-dialog.shareOther {
  height: 30% !important;
  border-radius: 10px;
}

.modal-content.shareOther {
  height: 70% !important;
  overflow: hidden !important;
  padding-top: 20px !important;
  text-align: center !important;
}
.modal-body.shareOther {
  overflow: initial;
}

.modal-dialog.service.modal-xl.modal-dialog-centered {
  height: 100% !important;
}

.modal-content.service {
  height: 78% !important;
}

.modal-dialog.product.modal-xl.modal-dialog-centered {
  height: 100% !important;
}

.modal-content.product {
  height: 78% !important;
}

@media screen and (min-width: 900px) and (max-width: 1500px) {
  .modal-content.service {
    height: 98% !important;
  }

  .modal-content.product {
    height: 98% !important;
  }

  /* .modal-dialog {
        height: 80% !important;
        padding-top: 5%;
      }
      .modal-body {
        height: 80%;
        overflow: auto;
      } */

  .modal-content {
    height: 100% !important;
    overflow: visible;
  }
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  padding: 0;
  overflow: hidden;
}
.validationFormik04 {
  /* outline: 1px solid !important; */
}
.input_outline {
  outline: 1px solid #e3e3e3 !important;
}
.footer_center {
  justify-content: center !important;
}
.navs_b {
  background-color: #f7f7f7;
}
.hair_and_makeup_card_2 {
  background-color: #f7f7f7;
}
.nav_link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 1px solid #c1c1c1;
  padding: 9px 51px !important;
  color: #edac3e;
  width: 229px;
  border-radius: 0px;
  margin: 0px 8px;
}
.star_pettren_1 span {
  color: #666666;
  font-size: 16px;
}
.hair_and_makeup_card_h11 {
  /* margin-top: ; */
}
.give_rating_4 {
  border-radius: 0px;
  background-color: #edac3e;
  border: 1px solid #edac3e;
  margin-right: 10px;
  margin-top: -15px;
  padding: 5px 15px;
  transition: all 0.5s;
}
.give_rating_4:hover {
  background-color: white !important;
  border: 1px solid #edac3e !important;
  color: #edac3e !important;
}

.react-calendar__navigation {
  background-color: #edac3e !important ;
}
.react-calendar__navigation button:disabled {
  background-color: #edac3e !important ;
}

.react-calendar {
  width: 100% !important;
  height: 314px !important;
}
.react-calendar__navigation__label {
  color: white !important;
}
.react-calendar__navigation__label {
  color: white !important;
  background-color: #edac3e !important ;
}
.react-calendar__tile--now {
  background-color: #edac3e !important;
  color: white !important;
}

.react-calendar__tile--active {
  background-color: lightskyblue !important;
}
.bussiness_name_label {
  margin-bottom: 10px;
}
.form_control_1 {
  border: none !important;
  width: 80%;
  /* outline: 1px solid black !important; */
}
.form_control_1:focus {
  color: #212529;
  background-color: #fff;
  /* border-color: #86b7fe; */
  outline: 1px solid black;
  /* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */
}
.cercile_check {
  height: 100px;
  width: 100px;
  background-image: url("../src/app/assets/images/orderComplete.jpg");
  background-size: 100%;
  display: flex;
  margin: 10px auto;
}
.cercile_check_h1 {
  color: black;
  text-align: center;
  font-size: 35px;
}

.review__modal {
  width: 600px !important;
  min-width: 600px !important;
}

.review__textarea {
  resize: none;
  background-color: #f7f7f7;
  /* outline: ; */
  border: none;
  width: 90%;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 auto;
  padding-left: 12px;
  padding-top: 4px;
  resize: none;
}
.css-1p7b7rs-MuiBadge-badge {
  background-color: #edac3e !important;
}
.order_date {
  line-height: 18px;
  font-size: 15px;
}
.order_date_1 {
  font-weight: 600;
  color: #5a5a5a;
  letter-spacing: 0.5px;
  line-height: 10px;
  font-size: 17px;
  font-family: "Rufina";
}
.order_date_3 {
  color: #ecab3d;
  line-height: 15px;
  font-size: 18px;
  margin: 0px !important;
  /* padding: 5px 0px !important; */
  padding-bottom: 8px;
}
.ongoing_section_right_6 {
  font-family: "Rufina";
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 10px;
    text-transform: capitalize;
    color: #5a5a5a !important;
}

.review_modal_img{
  height: 120px;
  width: 120px;
  display: block;
  margin: 0px auto;
}
.card_corrtive_5{
  text-align: center;
  font-size: 22px;
  font-family: "Rufina";
}
.card_corrtive_6{
  text-align: center;
  font-size: 18px;
  font-family: "Rufina";
  color: #EDAC3E;
}
.card_corrtive{
  height: auto;
  background-color: #F9F9F9;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
}
.star_demo i{
  font-size: 13px;
}
.card_corrtive_inner_h5{
  font-size: 18px;
}
.card_corrtive_inner_p{
  font-family: "Rufina";
  color: #858282;
}
.card_corrtive_inner_7{
  color: #EDAC3E;
  font-size: 15px;
}
.card_corrtive_inner_span_7{
  color: #555555;
}
.veiw_review_bttn{
    border: 1px solid #EDAC3E;
    padding: 12px 56px;
    color: white;
    background-color: #EDAC3E;
}
.offer_modal_height{
  height: 70% !important;
}

/* Earning page start */

.earning_select{
  /* border: 1px solid black; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px #b1b1b1;
}
.earning_select_bar{
  width: 200px;
  border: 1px solid #EDAC3E;
  float: right;
}
.earning_select_h5{
    font-family: "Rufina";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.total_produt_earning{
  box-shadow: 0px 0px 10px #b1b1b1;
  border-radius: 10px;
}
.total_produt_earning h1{
  font-size: 22px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
  color: #595959;
}
.total_produt_earning h5{
  text-align: center;
  color: #ecab3d;
  font-size: 35px;
  font-family: "Rufina";
  font-style: normal;
  font-weight: 600;
}
.service_name_input_1{
  border-radius: 0px !important;
  padding: 5px;

}
.service_name_input_2{
  padding: 5px;
}
.base_h6{
  font-size: 19px !important;
}
.base_h7{
  font-size: 19px !important;
  line-height: 45px !important;
}
.add_border_secription{
  border-radius: 5px;
  padding: 15px 0px;
  height: 100%;
}
.base__li{
  margin-top: -11px !important;
}


.text_light_2{
  margin-top: -10px !important;
}
.text_light_3{
  margin-top: -10px !important;
}
.btnn_bottom{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
}
.add_border_secription_2{
  position: relative;
  padding-bottom: 70px !important;
}
.form_control_11:focus {
  color: #212529;
  background-color: #fff;
  border-color: black;
}
.form_control_11{
  border-radius: 0px !important;

}
.service_name_input_11 {
  border: none;
  border-bottom: 1px solid rgb(207 207 207);
  color: #646464 !important;
  width: 100%;
  line-height: 39px;
  font-size: 15px;
  outline: none;
  /* height: 50px !important; */
  background-color: transparent !important;
  
}

/* live chat */
.live_chat{
  border: 1px solid black !important;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}
.chat_inbox{
  height: auto;
  background-color: #EDAC3E;
}
.chat_chat{
  height: auto;
  background-color: #EDAC3E;
}
.user_img{
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid white;
}
.user_name_gaurav{
    font-weight: 600;
    font-family: "Rufina";
    font-style: normal;
    color: white;
    letter-spacing: 1px;
}
.search_bar{
  border: 1px solid #ABABAB;
  border-radius: 5px;
}
.search_bar i{
  border-radius: 10px;
  color: #ABABAB;
  
}
.search_bar input{
  outline: none;
  width: 80%;
}
.card_massge{
  height: auto;
  padding-top: 5px;
  /* border-bottom: 1px solid #ABABAB; */
  /* margin-bottom: -21px; */
  cursor: pointer;
}
.card_massge:hover{
  background-color: whitesmoke;
  cursor: pointer;
}
.live_chat_left{
  border-right: 1px solid #ABABAB;
  height: 700px;
}

.user_name h5{
  font-size: 18px;
  font-family: 'rufina';
  font-style: normal;
  font-weight: 800;
  color: #EDAC3E;
}
.user_name p{
  color: #ABABAB;
  font-size: 15px;
}
.massage_timme{
  color: #ABABAB;
  font-size: 15px;
}
.chat_list_scroll_bar{
  overflow: scroll;
  height: 545px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chat_list_scroll_bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat_list_scroll_bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.massage_hour{
  background-color: #EDAC3E;
  padding: 5px 10px;
  border-radius: 2px;
  color: white;
  /* display: block; */
  /* margin: auto; */
}
.side_msg{
  float: right;
}
.side_msg_spann{
  background-color: #EDAC3E;
  height: 40px;
  padding: 7px 20px;
  padding-right: 70px;
  width: auto;
  border-radius: 5px;
  margin-top: 20px;
  color: white;
  position: relative;
}
.side_msg_spann span{
  font-size: 12px;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.live_chat_left_right{
  position: relative;
}
.other_search_bar{
  background-color: #EDAC3E;
  height: 50px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.socail_icon{
  width: 30px;
  margin: 0px 10px;
}
.other_search_bar_in input{
  width: 85%;
  margin-top: 5px;
  padding: 7px 10px;
  border-radius: 7px;
  outline: none;
}
.dot_icon{
   /* font-weight: 600; */
    /* font-family: "Rufina"; */
    font-style: normal;
    color: white;
    font-size: 20px;
    letter-spacing: 1px;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .other_search_bar_in input{
    width: 80%;
  }
  .side_msg_spann{
    height: auto !important;
  }
}
@media screen and (min-width: 772px) and (max-width: 992px) {
  .other_search_bar_in input{
    width: 70%;
  }
  .side_msg_spann{
    height: auto !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 776px) {
  .other_search_bar_in input{
    width: 70% !important;
  }
  .live_chat_left{
    height: auto !important;
  }
  .side_msg_spann{
    height: auto !important;
  }
  .total_produt_earning {
    margin: 0px !important;
  }
}
/* .ongoing_section_left img{
  border-radius: 100px;
} */
.img_roundd{
  width: 150px;
  border-radius: 100px;
}
.review__modal_2{
  height: 60% !important;
}

.review__modal__img img{
   height: 100px;
   width: 100px;
   background-color: #020250;
   border-radius: 100px;
   box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

/* new change slide bar */

@media screen and (min-width:992px) and (max-width: 1200px) {
  .editProfile{
    font-size: 17px !important;
  }
}

/* cencel order  */
.cencel_btn{
  border: 1px solid #EDAC3E !important;
  color: #EDAC3E;
  border-radius: 4px !important;
  display: block;
  margin: 0px auto;
}
.cencel_btn i{
  color: red;
}
.orderCancel {
  background-color: #ffffff;
  padding: 6px 11px;
  color: red;
  text-decoration: none;
  border: 1px solid red !important;
  transition: all 0.5s;
  border-radius: 0px;
}
.chat_chat_start {
  height: 528px !important;
  overflow-y: scroll;

  
}

.promo_corde_sett{
  color: #EDAC3E;
  font-weight: 600;
}

.expiry_date{
  width: 45%;
  padding: 11px !important;
  margin: 10px;
}
.cancel_business_btn{
  color: red;
  border: 1px solid red;
  padding: 5px 10px;
}
select.service_name_inputt.form-control.form_control_11 {
  width: 100%;
      /* padding: 1rem 0.75rem; */
}
textarea.service_name_textarea.mt-4.form-control.form_control_11 {
  width: 100%;
}
input.form-control.form_control_11.service_name_input {
  width: 100%;
}
.maps_height{
  height: 610px !important;

}
/* Cancel Appoinment */

.cancel_appoinment{
  color: #ff6262;
  text-decoration: none;
  font-size: 17px;
}
.cancel_appoinment:hover{
  color: #ff6262 !important;
}
.span_top{
  margin-top: -35px !important;
}
.basic__detail__modal__content{
  height: 92% !important;
}
.basic__detail__modal__content_2{
  height: 65% !important;
}
input.service_name_inputt.mt-4.form-control.form_control_11 {
  width: 100%;
}
.add_drop li {
  display: grid !important;
}
/* .modal-backdrop.fade {
  opacity: 0.1 !important;
} */
.cancel_appoinment_2{
  position: absolute;
  right: 33px;
  border: 1px solid #d32f2f;
  padding: 2px 10px;
  color: white;
  background: #ff0101;

}
.cancel_appoinment_2:hover{
  color: red;
  background-color: #fff;
  transition: all 0.5s;
}


.cancel_msg{
  color: #EDAC3E;
  line-height: 0px;
}
@media screen and (min-width:992px) and (max-width:1200px) {
  .resposive_best_macth{
    font-size: 13px !important;
  }
}

.book_now_btn{
  border: 0.954545px solid #edac3e;
  padding: 15px 40px;
  background-color: white;
  text-decoration: none;
  color: #edac3e;
  transition: all 0.5s;
}
.book_now_btn:hover {
  background-color: #edac3e;
  color: white;
}

.notification_icons{
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px !important;
}
.dropdown_menu_2{
  width: 400px !important;
}
.review_notifaction h5{
  font-size: 17px;
}
.review_notifaction p{
  color: rgb(199, 198, 197);
  font-size: 13px;
  line-height: 10px;
}
.review_notifaction_icon i{
  color: #EDAC3E;
  font-size: 24px;
  padding: 10px 20px;
}
.accept_message{
  background-color: #fff;
  border: 1px solid #EDAC3E;
  padding: 0px 10px;
  box-shadow: none;
  border-radius: 2px;
  color: #EDAC3E;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu;
  margin-top: -20px !important;
}
.review_section{
  font-weight: 700;
  font-family: "Rufina";
}
.veiw_review_bttn_2{
  font-size: 15px;
  padding: 5px 25px;
}
ul.dropdown-menu.add_drop.show{
  position: absolute ;
  left: -95px !important;
}
.dropdown_menu_2{
  position: absolute ;
  left: -165px !important;
}
@media screen and (min-width:320px) and (max-width:992px)  {
  ul.dropdown-menu.add_drop.show{
    position: absolute ;
    left: 0px !important;
  }
  .dropdown_menu_2{
    position: absolute ;
    left: 0 !important;
  }
}


/* loader start */
ul.dropdown-menu.dropdown_menu_2.pb-0.overflow-hidden.show {
  height: 327px;
  overflow-y: scroll !important;
}

/* ==================Payment Page Success and Cancel Start ================*/

.payment_failed a{
  color: #ffffff;
  padding: 7px 40px;
  /* width: 150px; */
  background-color: #edac3e;
  border: 1px solid #edac3e;
  border-radius: 5px;
  text-align: center;
  /* display: block; */
  margin: auto;
  text-decoration: none;
}
.payment_failed h1{
  color: rgb(70, 70, 70);
  line-height: 50px;
}
.payment_failed h5{
  color: rgb(102, 102, 102);
  line-height: 40px;
}
.payment_failed img{
  width: 100px;
  display: block;
  /* margin: ; */
}
.payment_failed_screen{
  background-color: #f7f7f7;
  border-radius: 10px;
}
.payment_failed_right img{
  width: 60%;
  display: block;
  margin: auto;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .payment_failed_right img{
      width: 80%;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .payment_failed_right{
     float: left;
  }
  .resposive_z{
      display: block !important;
  }
  .resposive_y img{
      display: none !important;
  }
  .payment_failed img{
      display: block;
      margin: auto;
  }
  .payment_failed h1{
      color: rgb(70, 70, 70);
      line-height: 20px;
      font-size: 20px;
      text-align: center;
  }
  .payment_failed h5{
      color: rgb(102, 102, 102);
      /* line-height: 40px; */
      font-size: 15px;
      text-align: center;
  }
  .payment_failed a{
      display: block;
      margin: auto;
  }
  .payment_failed_2 img{
    width: 100% !important;
    display: block;
    margin: auto;
  }
}
.payment_failed_2 img{
  width: 400px;
  display: block;
  margin: auto;
}
.payment_failed_2 h1{
  color: #edac3e;
  line-height: 50px;
  text-align: center;
}
.payment_failed_2 h5{
  color: rgb(102, 102, 102);
  line-height: 40px;
  font-size: 15px;
  text-align: center;
}
.payment_failed_2 a{
  background-color: #edac3e;
  border: 1px solid #edac3e;
  border-radius: 5px;
  color: #fff;
  /* display: block; */
  /* margin-top: 57px !important; */
  padding: 11px 40px;
  text-align: center;
  text-decoration: none;
  width: 242px;
  margin: 5px;

}

.fc .fc-daygrid-day-number {
  padding: 4px;
  position: relative;
  z-index: 4;
  color: black;
  text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: black;
  text-decoration: none;
}

.fc-view-harness {
  height: 560px !important;
}
.span_div{
  float: right;
  margin-top: -53px;
}
.span_div i{
  color: #EDAC3E;
  font-size: 30px;
  margin-right: 20px;
}

.dotNotific {
  padding: 1px;
 color: #EDAC3E;
 font-size: 10px;
  /* border-radius: 100%; */
  /* height: 2px; */
  /* width: 2px; */
}
.makeStyles-bookedText-74 {
  color: black;
  font-size: 12px !important;
  position: absolute !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.loader-logo {
  width: 200px; /* Adjust the width and height as needed */
  height: 100px;
  margin-bottom: 20px;
}

.loader-spinner {
  border: 4px solid black;
  border-top: 4px solid #EDAC3E;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.add_card_new {
  color: #EEAC3E;
  text-decoration: none;
}
.add_card_new:hover {
  color: #EEAC3E;
  text-decoration: none;
}
div#demo-controlled-open-select {
  width: 320px !important;
  display: block;
}
.MuiFormControl-root.css-1869usk-MuiFormControl-root {
  display: block;
  margin: 0px 50px;
  justify-content: center;
  align-items: center;
}
.help1:hover{
  cursor: pointer;
}
.help_page h1{
  font-weight: 800 !important;
  font-size: 50px;
}
.help_page h6{
  color: #716f6f
}
.img_wrapper_1{
  width: 500px;
}

.img_wrapper_1 img{
  height: 100%;
  width: 100%;
}
.help_page_form input, textarea, select{
  background: #f7f9f8 !important;
}
.help_page_form .email-us{
  text-decoration: underline;
}


.help_page_form button{
  /* float: right; */
  background-color: #ecab3d;
  border: 1px solid white;
  transition: all 0.5s;
}
.help_page_form button:hover{
  /* float: right; */
  background-color: white !important;
  border: 1px solid #ecab3d !important;
  color: #ecab3d !important;
}
.help_page_form button span{
  margin-left: 8px;  
}

.help_page_form button i{
  margin: 0px 8px;
}
.link_decorations{
  text-decoration: none;
  color: black;
}
.link_decorations:hover{
  text-decoration: none;
  color: #2e2e2e;
}
.link_decorations_white{
  text-decoration: none;
  color: rgb(211, 211, 211);
}
.link_decorations_white:hover{
  text-decoration: none;
  color: white !important;
}

.jss3 {
  /* color: #edac3e; */
  width: auto !important;
  /* border: 1px solid #edac3e; */
  /* border-radius: 0; */
  /* background-color: white; */
}

.about-us {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.about_uss h1{
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 56px;
  text-align: center;
  text-transform: capitalize;
}
.about_uss p{
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #747474;
}
.about_uss_2 h1{
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* line-height: 56px; */
  /* text-align: center; */
  text-transform: capitalize;
}
.about_uss_2 p{
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* text-align: center; */
  color: #747474;
}
.about_uss_1{
  background-color: rgb(251, 245, 239);
}
.about_uss_4 img {
  width: 100%;
  border-radius: 10px;
  height: 350px;
}
.about_uss_4 h1{
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 56px;
  /* text-align: center; */
  /* text-transform: capitalize; */
}
.about_uss_4 p{
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 10px;
  color: #747474;
  /* text-align: center; */
}
.link_color_change a{
  color: #757270;
  text-decoration: none;
  font-weight: 500;
}

.withdrawal_screen{
  transition: all 0.5s;
  padding: 10px 50px;
  background-color: #EDAC3E;
  color: white;
  text-decoration: none;
  border: 1px solid #EDAC3E;
  border-radius: 2px;
}
.withdrawal_screen:hover {
  background-color: #FFFFFF;
  border: 1px solid #EDAC3E;
  color: #EDAC3E;
  transition: 0.5s;
}
 .withdrawal_form table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.withdrawal_form th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.withdrawal_form th {
  background-color: #F2F2F2;
}
.withdrawal_form tr:hover {
  background-color: #F5F5F5;
}
.withdrawal_form_amount_input{
  padding: 10px 5px;
  background-color: white;
  width: 90%;
  border-bottom: 1px solid black;
}
.withdrawal_form_amount_input:focus{
  padding: 10px 5px;
  background-color: white;
  border-bottom: 2px solid #ECAB3D !important;
  outline: none;
}
.earning_select_h5{
  font-weight: 500;
  color: #ECAB3D;
}
.withdrawal_form2{
  background: -webkit-linear-gradient(left, #ECAB3D, #ECAB3D) !important;
  background: linear-gradient(to right, #ECAB3D, #ECAB3D) !important;
  font-family: 'Roboto', sans-serif;
}
.withdrawal_form1 table{
  width:100%;
  table-layout: fixed;
}
.tbl-content{
  height:300px;
  overflow-x:auto;
  margin-top: 0px;
  border: 1px solid rgba(255,255,255,0.3);
}
.withdrawal_form1 th{
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}
.withdrawal_form1 td{
  padding: 15px;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 15px;
  color: black;
  border-bottom: solid 1px solid 1px rgb(0 0 0 / 10%);
}
/* demo styles */
/* for custom scrollbar for webkit browser*/
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.withdrawal_form_amount_input_2{
  color: #5C5C5C;
  font-family: 'Roboto';
  float: right;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  iframe#widget2 {
    width: 320px !important;
}
.img_wrapper_1 {
  width: 320px !important;
}
img.w-full.bg-gray-50 {
  width: 304px !important;
}
 .input_search{
  margin-top: 10px;
 }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .custom__modal {
    min-width: 320px!important;
}
.ask_for_add_review_upper_afterLogin {
  float: left;
  margin-top: 0px !important;
  margin-right: -114px;
}
}

.inputtypes_deatils{
  width: 100%;
  padding: 10px 10px;
  border: 1px solid darkgrey;
  border-radius: 5px;
  outline: none;
}
.inputtypes_deatils_h5{
  color: #ECB240;
}
.inputtypes_deatils_main{
  background-color: #F9F9F9;
  margin-left: -12px !important;
  padding:10px;
  border-radius: 5px;
}
.see_time_btn_77{
  padding: 10px 50px;
  background-color: #EDAC3E;
  color: white;
  text-decoration: none;
  border: 1px solid #EDAC3E;
}
.see_time_btn_77:hover{
  padding: 10px 50px;
  background-color: white;
  color: #EDAC3E;
  text-decoration: none;
  border: 1px solid #EDAC3E;
}
.inputtypes_deatils_main1 label {
  color: #000000;
    font-family: "Rufina";
    font-size: 18px;
    padding-bottom: 8px;
}
.fc-timegrid-slot-label {
  text-transform: uppercase; /* Convert text to uppercase */
}


.inputtypes_deatils_main_h5{
  text-decoration: none;
    color: black;
    padding-left: 10px;
    font-size: 18px;
    padding-top: 10px;
}
.inputtypes_deatils_main_h5_span{
   float: right;
    margin-right: 27px;
}
.inputtypes_deatils_main:hover{
  cursor: pointer;
}
.send_pera_light{
  color: #7B7B7B;
  /* font-family: Rufina; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.send_pera_dark{
  color: #595959;
  /* font-family: Rufina; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ask_for_add_review22 {
  background-color: #ffffff;
  padding: 10px 80px;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
}

.ask_for_add_review22:hover {
  background-color: #edac3e !important;
  color: #ffffff !important;
  border: 1px solid #f8f8f8 !important;
}
.justify{
  justify-content: center !important;
}



.toggle_btn_1[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 31px;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
}

.toggle_btn_1[type="checkbox"]::before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #EDAC3E;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.toggle_btn_1[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.toggle_btn_1[type="checkbox"]:checked {
  background: #EDAC3E !important;
}
.toggle_btn_1{
  float: right;
}
.modal_header{
  display: inline !important;
}
.mange_vacations{
  margin-top: -17px ;
}
.mange_vacations_h5{
  color: #121111;
  /* font-family: SpaceGrotesk; */
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-top: -17px ;

}
.mange_vacations_p{
  color: #121111;
  /* font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,sans-serif; */
  font-size: 14px;
  line-height: 20px;

}
.add_new_vacation{
  width: 100% !important;
    margin: 10px 0px;
    display: block;
    background-color: #edac3e;
    transition: all 0.5s;
    text-decoration: none;
    padding: 7px;
    color: white;
  border: 1px solid #edac3e;

   
}
.add_new_vacation:hover{
  background-color: white;
  border: 1px solid #edac3e;
  color: #edac3e;
}
.new_box{
  background-color: rgb(251, 247, 243);
  flex: 1 1 0%;
  padding: 12px 10px 1px 10px;
  text-align: left;
  position: relative;

}
.new_box p{
  padding-bottom: 0px !important;
  line-height: 20px;
}
.new_box a{
  position: absolute;
  right: 38px;
  bottom: 31px;
}
.inputtypes_deatils_main_h5_span1{
  color: #EDAC3E;
font-family: Rufina;
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.inputtypes_deatils_main_h5 {
  color: #000;
  font-family: Rufina;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inputtypes_deatils_main_h5_span_2{
  border-radius: 4px;
background: rgba(237, 172, 62, 0.22);
float: right;
font-size: 17px;
padding:8px 10px;
}
.inputtypes_deatils_main_img{
  float: right;
  width: 25px;
  margin: 5px 10px;
}
.markting_plan{
  color: #222;
  font-family: Rufina;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.display_setting{
  display: inline;
}
.see_time_btnn2{
  width: 100%;
}
.markting_plan_ul li{
  color: #000;
  font-family: Rufina;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  padding-top: 5px;
}
.markting_plan_ul {
  list-style-image: url('app/assets/images/list_icon.png');
  padding-left: 25px; /* To provide space for the checkmark */
  
}
.markting_plan_ul {
  list-style-type: none;
  padding-left: 25px; /* To provide space for the checkmark */
}
.support_content span{
  color: #edac3e;
}
.instagram_content{
  font-family: "Rufina";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 27px;
  /* identical to box height */
  text-transform: capitalize;
  color: #4e4e4e;
}


.inputtypes_deatils_main1_h6{
  font-size: 21px;
  font-family: "Rufina";
}

button.btn.editt {
  border: 1px solid #edac3e;
  color: #edac3e;
  float: right;
  /* padding: 10px 20px; */
  /* margin-top: -13px !important; */
}
button.btn.editt:hover {
  border: 1px solid #edac3e;
  background-color: #edac3e;
  color: white;
  float: right;
}

button.button_z {
  position: relative;
  top: 40% !important;
}
.inputtypes_deatils_main_h5_span2{
  float: right;
  margin-top: 13px;
}
.inputtypes_deatils_main_h5_span2 button{
  padding: 8px 20px !important;
    background-color: #edac3e;
    color: white;
    border: 1px solid #edac3e;
    margin-top: -4px;
    transition: all 0.5s;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 0px !important;

}
@media screen and (min-width: 320px) and (max-width: 576px) {
  .chevron{
    /* position: static !important; */
    left: 0px !important;
    right: 0px !important;
  }
}

.profile_dis_user_name{
  color: #000;
  font-family: Rufina;
  font-size: 20px;
  font-style: normal;
  word-wrap: break-word;
  font-weight: 700;
  line-height: 80px; /* 400% */
}
.profile_dis_img{
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.find_by_category_h55{
  color: #2E2E2E;
font-family: Rufina;
font-size: 18px;
font-style: normal;
font-weight: 700; 
line-height: normal;
text-align: center;
}
.newfind_by_category{
  background: #EFEFEF;
}
.profile_dis_user_name_h1{
  color: #000;
text-align: center;
/* font-family: Inter; */
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 140% */
text-transform: capitalize;
}
.Profile_sign_btn_2{
  padding: 12px 20px !important;
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  width: 90%;
  border-radius: 0px !important;
  margin: 0px 40px;
}
.Profile_sign_btn_2:hover {
  background-color: rgb(252, 252, 252) !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}
.Profile_sign_btn_4{
  padding: 12px 20px !important;
  background-color: white;
  color: #edac3e;
  border: 1px solid #edac3e;
  margin-top: -4px;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  width: 90%;
  border-radius: 0px !important;
  margin: 0px 40px;
}
.Profile_sign_btn_4:hover {
  background-color: rgb(252, 252, 252) !important;
  background-color: #edac3e !important;
  color: white !important;
  border: 1px solid #edac3e !important;
}
.profile_dis_user_name_p{
  text-align: center;
  color: #A7A7A7;
text-align: center;
/* font-family: Inter; */
font-size: 15px;
font-style: normal;
/* font-weight: 600; */
line-height: 28px; /* 186.667% */
text-transform: capitalize;
}
.new_input{
  border: none;
  border-bottom: 1px solid #EAEAEA !important;
  border-radius: 0px !important;

}
.new_input:focus{
  border-bottom: 1px solid #EAEAEA !important;
}
.new_header_new_list li{
  display: inline-block;
  padding: 10px 20px;
  color: #000;
/* font-family: Inter; */
font-size: 17.18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.new_header_new_list{
  float: right;
}
.Profile_sign_btn_2:hover .Profile_sign_btn_2 svg{
  fill: red;
}
.import_content{
  color: #8B8B8B;
text-align: center;
/* font-family: Rufina; */
font-size: 18px;
font-style: normal;
/* font-weight: 700; */
line-height: 31px; /* 140.909% */
text-transform: capitalize;
width: 80%;
}

/* ====== Appointment calender ======= */

.content_text h5{
  text-align: center;
}
.label_date{
  font-size: 15px;
  margin-top: 15px;
}
.add_card_btn{
  width: 100%;
  background-color: #EDAC3E;
  border: 1px solid #EDAC3E;
  padding: 5px 0px;
  color: white;
  margin-top: 15px;
  border-radius: 5px;
}
.form_select_new{
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #CED4DA;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.plusm_icon_new{
  color: #EDAC3E;
  font-size: 25px;
  margin-top: 5px;
}
.css-1dimb5e-singleValue {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 15px !important;
  font-weight: 400;
}
.new_edit_profile{
  border-radius: 100px;
  height: 100px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.profile_dis_user_nameh{
  color: #000;
  font-family: Rufina;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-top: 10px;
  word-wrap: break-word;
}
.reduis_angle1 {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  border: 1px solid #edac3e;
  font-size: 20px;
  background-color: #edac3e;
}
.reduis_angle1 .fa-envelope {
  color: white;
  padding: 14px 14px;
}
.footer_nd_list1 li {
  display: inline-block;
  padding: 0px 10px;
}
.footer_nd_list1 {
  /* float: right; */
}
.reduis_angle1 .fa-message {
  color: white;
  padding: 15px 14px;
}
.reduis_angle1 .fa-phone {
  color: white;
  padding: 14px 14px;
}
.top_one{
  background-color: #d5d5d5;
}
.edit_btnn_new{
  background-color: #edac3e;
  color: white;
  border: 1px solid #edac3e;
  padding: 5px 20px;
  border-radius: 5px;
  margin-top: -6px;
}

.css-1jqq78o-placeholder {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}
.fonts_i p i{
  font-size: 25px;
  margin-top: 20px;
  /* margin-left: -25px; */
  /* position: relative; */
    left: -33px;
}
.fonts_i1 i{
  font-size: 25px;
  margin-top: 20px;
  margin-left: 0px;
}
.hair_and_makeup_card_h551 {
  font-family: "Rufina";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #8f8f8f;
    line-height: 28px;
}
.hair_and_makeup_cardd{
  background-color: #e7e7e7;
  padding: 10px;
}
.invite_profile{
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.new_pera{
  line-height: 10px;
}
.invite_ul li{
  list-style: none;
}
.invite_ul{
  margin-left: -25px;
}
.invite_ul li i{
  margin-right: 5px;
}
.invite_header{
  background-color: #edac3e;
  
}

.fonts_i11 i {
  font-size: 25px;
  margin-top: 20px;
  /* margin-left: 16px; */
}
.spann_right{
  float: right;
  /* color: #ecb240; */
}
.z-query-width{
  width: 95%;
}
.service_name{
  color: #ecb240;
}
.service_price{
  float: right;
  color: #ecb240;
}
.view_more_service{
  font-size: 13px;
  margin-left: 1px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  
}

.disabled_button {
  background-color: #f2f2f2;
  color: #a3a3a3;
  cursor: not-allowed;

}
.new_section_copy_link{
  border: 3px dashed white;
  padding: 8px 50px;
  text-align: center;
  border-radius: 10px;
  padding-top: 15px;
  background: #edac3e;
  color: white !important;
}
.new_modal_title{
  margin: 10px 20px 12px 10px;
}
.new_section_copy_link_2{
     /* border: 3px dashed white; */
     padding: 5px 7px;
     text-align: center;
     width: 134px;
     /* border-radius: 10px; */
     padding-top: 15px;
     background: #edac3e;
     color: white !important;
     position: absolute;
     right: 32px;
     top: -5px;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .service_list_resposive{
    margin-top: 100px;
  }
  .about_uss_4 img{
    height: 250px !important;
  }
}


















.flex2 {
  display: flex;
  flex-direction: row wrap;
  align-items: center;
}
.flex2.justify-space-between {
  justify-content: space-between;
}
.card2 {
  padding: 50px;
  margin: 50px auto;
  max-width: 850px;
  background: #fff;
  font-family: 'DM Sans', sans-serif;
  border-radius: 6px;
  box-sizing: border-box;
}
.card2 .container {
  max-width: 700px;
  margin: 0 auto;
}
.card2 .card-title {
  margin-bottom: 50px;
}
.card2 .card-title h2 {
  margin: 0;
}
.card2 .card-body .payment-type,
.card2 .card-body .payment-info {
  margin-bottom: 25px;
}
.card2 .card-body .payment-type h4 {
  margin: 0;
}
.card2 .card-body .payment-type .types {
  margin: 25px 0;
}
.card2 .card-body .payment-type .types .type {
  width: 30%;
  position: relative;
  background: #F2F4F7;
  border: 2px solid #E8EBED;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease;
}
.card2 .card-body .payment-type .types .type:hover {
  border-color: #28333B;
}
.card2 .card-body .payment-type .types .type:hover .logo,
.card2 .card-body .payment-type .types .type:hover p {
  color: #28333B;
}
.card2 .card-body .payment-type .types .type.selected2 {
  border-color: #ECAB3D;
  background: rgba(255, 220, 64, 0.1);
}
.card2 .card-body .payment-type .types .type.selected2 .logo {
  color: #ECAB3D;
}
.card2 .card-body .payment-type .types .type.selected2 p {
  color: #28333B;
}
.card2 .card-body .payment-type .types .type.selected2::after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  height: 40px;
  width: 40px;
  top: -21px;
  right: -21px;
  background: #fff;
  border: 2px solid #ECAB3D;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card2 .card-body .payment-type .types .type .logo,
.card2 .card-body .payment-type .types .type p {
  transition: all 0.5s ease;
}
.card2 .card-body .payment-type .types .type .logo {
  font-size: 48px;
  color: #8A959C;
}
.card2 .card-body .payment-type .types .type p {
  margin-bottom: 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #8A959C;
}
.card2 .card-body .payment-info .column {
  width: calc(50% - 25px);
}
.card2 .card-body .payment-info .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card2 .card-body .payment-info .title .num {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #ECAB3D;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 12px;
  font-size: 12px;
}
.card_details{
  color: #8A959C;
  font-size: 15px;
}
.more_options_icon{
  color: black;
  text-align: center;
}
.new_xmark_icon{
  color: #ecab3d;
    position: relative;
    top: -20px;
    right: -20px;
    font-size: 24px;
}
.top_row{
  position: relative;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .chevron{
    /* position: static !important; */
    left: 0px !important;
    right: 0px !important;
  }
  .browse_products {
    text-align: center;
  }
  .row.animate__animated.animate__backInUp {
    text-align: center;
    margin: auto !important;
    justify-content: center;
}
.mt-5.px-1.animate__animated.animate__backInUp{
  text-align: center;
    /* margin: auto !important; */
    justify-content: center;
}
.secrch_btn{
  width: 100%;
}
button.secrch_btn1.Home{
  width: 100%;
}
.input_search {
  width: 100%;
}
.footer_down{
  text-align: center;
}
.col-md-7.as_customer_right.ps-5 {
  text-align: center;
}
.create_account_btn{
  display: flex;
}
.ask_for_add_review2{
  margin-left: 0px !important;
}
button.btn.ask_for_add_review3 {
  margin-left: 0px !important;
}
.button_a1{
  padding: 12px 32px;
}
.button_a2{
  padding: 12px 32px;
}
.hair_and_makeup_card_h55{
  text-align: center;
}
.inputtypes_deatils_main_h5 {
  font-size: 15px !important;
}
.total_produt_earning {
  margin-top: 20px !important;
}
.footer_nd_list {
  float: none !important;
}
.earning_select{
  overflow-x: scroll;
}
.withdrawal_form1.mt-2.mb-4{
  width: 800px;
}
.withdrawal_screen{
  margin-top: 20px !important;
}
.withdrawal_form_amount_input_2 {
  /* color: #5C5C5C; */
  font-family: 'Roboto';
  float: inherit;
  display: block;
}
}
/* new start */




button.see_time_btn {
  width: 100%;
  margin-bottom: 20px;
}
.nevermind_btn {
  padding: 10px 95px;
  background-color: #ffffff;
  color: #edac3e;
  text-decoration: none;
  border: 1px solid #edac3e;
  transition: 0.5s;
  display: -webkit-box;
  text-align: center;
  display: block;
}
.woman_cut_left.mt-4 {
  margin-top: 50px !important;
}
.css-wkkax4 {
  position: inherit;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
}
/* .css-13r1y7v{
  position: absolute;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
} */
.css-wkkax4{
  position: inherit;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
}
.hair_and_makeup_card.pb-3 {
  /* text-align: center; */
}
button#pills-home-tab {
  /* width: 100%; */
}
li.nav-item.favourite.mx-3.mt-4 {
  /* width: 100%; */
}
button#pills-profile-tab {
  /* width: 100%; */
}
a.view_more_btn1 {
  padding: 15px 22px !important;
}
a.book_now_btn {
  padding: 15px 22px !important;
}

/* new start */

@media screen and (min-width: 320px) and (max-width: 576px) {
  .chevron{
    /* position: static !important; */
    left: 0px !important;
    right: 0px !important;
  }
  .browse_products {
    text-align: center;
  }
  .row.animate__animated.animate__backInUp {
    text-align: center;
    margin: auto !important;
    justify-content: center;
}
.mt-5.px-1.animate__animated.animate__backInUp{
  text-align: center;
    /* margin: auto !important; */
    justify-content: center;
}
.secrch_btn{
  width: 100%;
}
button.secrch_btn1.Home{
  width: 100%;
}
.input_search {
  width: 100%;
}
.footer_down{
  text-align: center;
}
.col-md-7.as_customer_right.ps-5 {
  text-align: center;
}
.create_account_btn{
  display: flex;
}
.ask_for_add_review2{
  margin-left: 0px !important;
}
button.btn.ask_for_add_review3 {
  margin-left: 0px !important;
}
.button_a1{
  padding: 12px 32px;
}
.button_a2{
  padding: 12px 32px;
}
.hair_and_makeup_card_h55{
  text-align: center;
}
.inputtypes_deatils_main_h5 {
  font-size: 15px !important;
}
.total_produt_earning {
  margin-top: 20px !important;
}
.footer_nd_list {
  float: none !important;
}
.earning_select{
  overflow-x: scroll;
}
.withdrawal_form1.mt-2.mb-4{
  width: 800px;
}
.withdrawal_screen{
  margin-top: 20px !important;
}
.withdrawal_form_amount_input_2 {
  /* color: #5C5C5C; */
  font-family: 'Roboto';
  float: inherit;
  display: block;
}
button.see_time_btn {
  width: 100%;
  margin-bottom: 20px;
}
.nevermind_btn {
  padding: 10px 95px;
  background-color: #FFFFFF;
  color: #EDAC3E;
  text-decoration: none;
  border: 1px solid #EDAC3E;
  transition: 0.5s;
  display: -webkit-box;
  text-align: center;
  display: block;
}
.woman_cut_left.mt-4 {
  margin-top: 50px !important;
}
.css-wkkax4 {
  position: inherit;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
}
/* .css-13r1y7v{
  position: absolute;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
} */
.css-wkkax4{
  position: inherit;
  top: 0;
  left: 0;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100%;
}
.hair_and_makeup_card.pb-3 {
  /* text-align: center; */
}
button#pills-home-tab {
  width: 100%;
}
li.nav-item.favourite.mx-3.mt-4 {
  width: 100%;
}
button#pills-profile-tab {
  width: 100%;
}
a.view_more_btn1 {
  padding: 15px 22px !important;
}
a.book_now_btn {
  padding: 15px 22px !important;
}
/*------ new start  */

.MuiBox-root.css-wkkax4 {
  overflow-y: scroll;
  height: 100%;
}
button.view_more_btn1.btn.btn-primary {
  margin-bottom: 32px;
}
.book_now_btn1 {
  margin-bottom: 32px;
}
button.ask_for_add_review3.mt-2{
  width: 100% !important;
}
button.button_a1.mt-2 {
  width: 100% !important;
}
button.fc-today-button.fc-button.fc-button-primary {
  margin-top: 20px;
}
button.btn.ask_for_add_review2 {
  width: 100%;
}
button.btn.ask_for_add_review22 {
  width: 100%;
}
button.btn.ask_for_add_review3 {
  width: 100% !important;
}
button.btn.ask_for_add_review32 {
  width: 100% !important;
  margin: 10px 0px;
}
.footer_add_margin{
  margin: 0px !important;
}
p.footer_lorem {
  width: 100%;
}
li.nav-item.ms-3.mt-2 {
  width: 92%;
}

}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .see_time_btn_serviceDetails {
    background-color: #EDAC3E;
    border: 1px solid #EDAC3E;
    color: #fff!important;
    cursor: pointer;
    padding: 10px 13px !important;
    text-decoration: none!important;
  }
  .setbutton {
    padding: 10px 18px !important;
  }
  
  a.btn.view_more_btn11 {
    margin-top: 20px;
}
}


@media screen and (min-width: 992px) and (max-width: 2000px) {
  .css-79ws1d-MuiModal-root {
    position: fixed;
    z-index: 1300;
    right: auto !important;
    bottom: 0;
    top: 100px;
    left: auto !important;
    width: 50%;
  }
  .css-wkkax4 {
    position: unset !important;
    /* top: 0; */
    /* left: 0; */
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    padding: 32px;
    min-width: 53% !important;
}
  }

  .my_zara_ull li{
    list-style: none;
  }
  .my_zara_ull p{
    margin-top: 10px;
    text-align: justify;
  }
  @media screen and (min-width: 992px) and (max-width: 2500px) {
    .col-lg-3.col-md-6.col-sm-12.mt-5.find_top.indooo {
      width: 20% !important;
  }
  }

  button.btn_back.btn_back_22 {
    /* float: right; */
    /* display: flex; */
    position: absolute;
    right: 52px;
    z-index: 900;
}
.input-container {
  position: relative;
  /* margin-bottom: 20px;  */
}

.service_name_input {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Adjust as needed */
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.floating-label {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  transition: top 0.3s, left 0.3s, font-size 0.3s;
  pointer-events: none;
  font-size: 16px; /* Adjust as needed */
}

.service_name_input:focus + .floating-label,
.service_name_input:not(:placeholder-shown) + .floating-label {
  top: -12px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  font-size: 12px; /* Adjust as needed */
  color: #007bff; /* Change color when focused */
}
.delete_service{
  background-color: #ffffff;
  padding: 10px 80px;
  color: #ff0303;
  text-decoration: none;
  border: 1px solid #ff1600 !important;
  transition: all 0.5s;
  border-radius: 0px;
  /* margin-top: 0px !important; */
  margin-left: 15px;
  padding: 10px 46px !important;
}

.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #edac3e;
}
.see_time_btn.disabled {
  background-color: #dddddd; /* Change to your desired color */
  color: #888888; /* Change to your desired color */
  cursor: not-allowed;
}
.cancel-button-layout{
  margin-top: 0px !important;
}
.clear-button {
  position: relative;
  background-color: transparent;
  color: #333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: yellow;
}

.clear-button::before {
  content: 'X';
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  font-size: 20px;
  color: red;
}

.clear-button:hover::after {
  color: white;
}
.arrow_icon{
  background-color: #ECAB3D;
  color: black;

  border: 2px solid #ECAB3D;

}
.arrow_icon:hover{
  background-color: transparent;
  color: #ECAB3D;

  border: 2px solid #ECAB3D;
  
}
.error {
    color: red;
}
@media screen and (min-width:320px) and (max-width: 800px) {
  .float-set{
    float: left !important;
    margin-top: 20px;
  }
  .buttonzz_1{
    font-size: 13px !important;
  }
  .hair_and_makeup_card_h5{
    text-align: left !important;
    padding-left: 10px;
  }
  .hair_and_makeup_card_h55{
    text-align: left !important;
    padding-left: 10px;
  }
  .star_pettren{
    text-align: left !important;
    padding-left: 10px;
  }
  .as_customer_right {
    padding-left: 20px !important;
  }
  .inputtypes_deatils_main {
    background-color: #F9F9F9;
    margin-left: 0 !important;
    padding: 10px;
    border-radius: 5px;
}
.css-1lmgk0u {
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  min-width: 400px;
  height: 518px;
  overflow: scroll;
}
.dropdown_menu_2 {
  width: 320px !important;
}
.payment_failed_2 a{
  display: block !important;
}
.css-13r1y7v {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  min-width: 100% !important;
  height: 518px;
  /* overflow: scroll; */
  
}
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .my-model {
    width: 59% !important;
    max-width: 100% !important;
}
.tingrii_a1 {
  height: 100px !important;
  width: 100px !important;
  border-radius: 100px;
}
}
.clear-notification-btn{
 background-color: #edac3e;
  padding: 7px 20px;
  color: white;
  text-decoration: none;
  border: 1px solid #edac3e !important;
  transition: all 0.5s;
  border-radius: 0px;
  /* margin-top: 0px !important; */
  margin-left: 15px;
}

.clear-notification-btn:hover {
  background-color: white !important;
  color: #edac3e !important;
  border: 1px solid #edac3e !important;
}
@media screen and (min-width: 992px) and (max-width: 2000px) {
  .css-wkkax4 {
      background-color: #fff;
      box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
      /* min-width: 53% !important; */
      padding: 32px;
      /* position: static !important; */
      transform: matrix(1, 0, 0, 1, 0, 0);
      margin: auto;
      left: 27%;
      right: 50%;
      top: 20%;
      width: 54% !important;
      /* position: relative !important; */
      margin-top: 50px;
  }
  .add_photo_2.az {
    /* margin-top: 91px !important; */
}
  
}
.col-md-3.rebond_img_setting.mt-3 {
  margin-top: 105px !important;
}

.css-13r1y7v {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
  /* min-width: 657px; */
  height: 518px;
  /* overflow: scroll; */
  
}
/* //Selct style */




.error-message {
  padding: 0px;
  margin: 0px;
}
.hide_passsword span a:hover{
  color: #007bff;
}
.nav-pills .editProfile .nav-link{
  width: 100% !important;
}
.text-decoration{
  text-decoration: none;
}
.col-3.bg-white.p-4.m-2 {
  margin: auto;
  text-align: center;
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
 
.tingrii_a1 {
  height: 100px !important;
  width: 100px !important;
  border-radius: 100px;
}
}
.buttonzz:hover, .buttonzz:focus {
  background: #ea2237;
  color: #fff;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}
.buttonzz {
  
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}
@media screen and (min-width:320px) and (max-width: 2000px) {
  .profile_dis {
    padding-bottom: 77px;
} 
}
span.float-end.icon-visivility {
  margin-top: -44px;
}
button:disabled {
  background-color: #ccc; /* Change the background color */
  color: #999; /* Change the text color */
  cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
  /* You can add more styles as needed */
}
.image-upload-setting{
  border-radius: 10px;
  margin-top: 10px;
}
.inputtypes_deatils_height{
  width:  21% !important;
  justify-content: right;
  float: right;
  height: 21px;
}
.custom-dropdown {
  position: relative; /* Required for absolute positioning of the dropdown */
}

.dropdown-list {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto; /* Add scroll if needed */
  width: 100%;
  z-index: 1000; /* Ensure the dropdown appears above other content */
}

.dropdown-item {
  /* padding: 8px 12px; */
  cursor: pointer;
  text-align: left;
  padding: 0px 8px;
  cursor: pointer;
  text-align: left;
  font-size: 15px;
}
.dropdown-list{
  padding-left: 0px !important;
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}
.add-card-btn{
    background-color: #ffffff !important;
    border: 1px solid #ffffff !important;
    /* padding: 7px 50px !important; */
    border-radius: 0px;
    width: 90%;
    transition: all 0.5s;
    text-align: left;
    box-shadow: 1px 1px 5px #9d9d9d;
    color: black !important;
}
.default-card{
  /* width: 100%; */
  height: 100px;
  background-color: red;
}
.overflow-scroll-bar-products {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.overflow-scroll-bar-products::-webkit-scrollbar { /* WebKit browsers */
  display: none; /* Safari and Chrome */
}
.add-icon{
  position: absolute;
  right: 17px;
  bottom: 17px;
  background: #edac3e;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 100px;
}
.processed-successfully{
  width: 50%;
}
.processed-successfully-content{
  font-size: 20px;
}
.form-control-setup {
  color: #212529;
  background-color: #fff;
  border-color: transparent;
  /* outline: 0; */
  box-shadow: none;
  width: 100% !important;
  padding: 10px !important;
  margin: 0px !important;
  line-height: 15px;
  height: 54px !important;
}
.form-control-setup {
  outline: 1px solid #e3e3e3 !important;
}