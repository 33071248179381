.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Changed to fixed to cover entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 9999; /* High z-index to ensure it's on top of other content */
}

.loader-content {
  text-align: center;
  padding: 20px;
  background: white; /* Optional: to contrast with the backdrop */
  border-radius: 10px; /* Optional: adds styling to the loader box */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Optional: adds shadow for depth */
}


.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Space between text and dots */
}

.dots span {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #333; /* Match to your site's color theme */
  margin: 0 5px;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: calc(.2s * var(--dot-index)); /* Calculated delay for each dot */
}

.dots span:nth-child(1) { --dot-index: 0; }
.dots span:nth-child(2) { --dot-index: 1; }
.dots span:nth-child(3) { --dot-index: 2; }

@keyframes dotFlashing {
  0% { background-color: #edac3e; }
  50%, 100% { background-color: transparent; }
}
